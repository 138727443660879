import React, { useEffect, useRef } from "react";
import { Button, ControlLabel, HelpBlock, Icon, IconButton } from "rsuite";

import { useTranslation } from "react-i18next";
import {
    IAllowedIpAddress,
    ISftpUserWithIpAddresses,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { isEveryWorkflowStepCompleted } from "utils/tenant-workflows/tenant-workflows-helper";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
} from "utils/tenant-workflows/tenant-workflows-types";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import IpAddressRow from "./IpAddressRow";
import "./SftpIpAddresses.less";
import cn from "classnames";

interface IComponentProps {
    isSftpExpanded: boolean;
    isSftpEnabled: boolean;
    sftpUserWithIpAddresses: ISftpUserWithIpAddresses;
    areSftpAddressesValid: boolean;
    disabledSettingNames: string[];
    enableSftpWorkflow: ITenantFeatureSettingWorkFlow | null;
    setSftpUserWithIpAddresses: (
        sftpUserWithIpAddresses: ISftpUserWithIpAddresses
    ) => void;
    setSftpAddressesValid: (isValid: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

const SftpIpAddresses: React.FC<IComponentProps> = ({
    isSftpExpanded,
    isSftpEnabled,
    sftpUserWithIpAddresses,
    areSftpAddressesValid,
    disabledSettingNames,
    enableSftpWorkflow,
    setSftpUserWithIpAddresses,
    setSftpAddressesValid,
    setIsUnsavedChangeAvailable,
}: IComponentProps) => {
    const { t } = useTranslation();
    const rowRefs = useRef<HTMLFormElement[]>([]);
    rowRefs.current.splice(0, rowRefs.current.length);

    const allowedIpAddresses =
        sftpUserWithIpAddresses?.allowedIpAddresses || [];

    const isCheckboxEnabled =
        enableSftpWorkflow !== null
            ? !disabledSettingNames.includes(
                  tenantFeatureSettingDefinitions[
                      TenantFeatureSettingWorkFlowType.EnableSftp
                  ].urlParameterValue
              ) && isEveryWorkflowStepCompleted(enableSftpWorkflow.steps)
            : true;

    const handleAddIpAddress = () => {
        const newIpAddress: IAllowedIpAddress = {
            id: null,
            label: `Label-${allowedIpAddresses.length + 1}`,
            cidr: "",
            editing: true,
        };
        const updatedIpAddresses = [...allowedIpAddresses, newIpAddress];
        setSftpUserWithIpAddresses({
            ...sftpUserWithIpAddresses,
            allowedIpAddresses: updatedIpAddresses,
        });
        setIsUnsavedChangeAvailable(true);
    };

    const handleIpAddressChange = (index: number, value: string) => {
        const updatedIpAddresses = allowedIpAddresses.map((ip, idx) =>
            idx === index ? { ...ip, cidr: value } : ip
        );
        setSftpUserWithIpAddresses({
            ...sftpUserWithIpAddresses,
            allowedIpAddresses: updatedIpAddresses,
        });
        setIsUnsavedChangeAvailable(true);
    };

    const handleFieldEdit = (index: number) => {
        const updatedIpAddresses = allowedIpAddresses.map((ip, idx) =>
            idx === index ? { ...ip, editing: !ip.editing } : ip
        );
        setSftpUserWithIpAddresses({
            ...sftpUserWithIpAddresses,
            allowedIpAddresses: updatedIpAddresses,
        });
    };

    const handleIpAddressDelete = (index: number) => {
        const updatedIpAddresses = allowedIpAddresses.filter(
            (_, idx) => idx !== index
        );
        setSftpUserWithIpAddresses({
            ...sftpUserWithIpAddresses,
            allowedIpAddresses: updatedIpAddresses,
        });
        setIsUnsavedChangeAvailable(true);
    };

    const validate = (): boolean => {
        let rowsValid = true;
        rowRefs.current.forEach((value: HTMLFormElement) => {
            if (value) {
                rowsValid = value.validate() && rowsValid;
            }
        });
        return rowsValid;
    };

    useEffect(() => {
        if (validate()) {
            setSftpAddressesValid(true);
        } else {
            setSftpAddressesValid(false);
        }
    }, [sftpUserWithIpAddresses.allowedIpAddresses]);

    const tableRows = allowedIpAddresses.map(
        (ipAddress: IAllowedIpAddress, index) => {
            const getRef = (e: any) => {
                if (e) {
                    rowRefs.current.push(e);
                }
            };
            return (
                <>
                    <tr
                        className={"sftp-id-addresses__table-row"}
                        key={String("rowIndex_" + index)}
                    >
                        <td className={"sftp-id-addresses__table-cell"}>
                            {ipAddress.editing ? (
                                <IpAddressRow
                                    cidr={ipAddress.cidr}
                                    rowIndex={index}
                                    handleIpAddressChange={
                                        handleIpAddressChange
                                    }
                                    ref={getRef}
                                />
                            ) : (
                                <div>{ipAddress.cidr}</div>
                            )}
                        </td>
                        <td className={"sftp-id-addresses__table-cell"}>
                            <>
                                <IconButton
                                    appearance="subtle"
                                    size="sm"
                                    onClick={() => {
                                        handleFieldEdit(index);
                                    }}
                                    icon={
                                        ipAddress.editing ? (
                                            <Icon icon={"save"} />
                                        ) : (
                                            <Icon icon={"pencil"} />
                                        )
                                    }
                                    disabled={
                                        !areSftpAddressesValid ||
                                        !isCheckboxEnabled
                                    }
                                />
                                <IconButton
                                    appearance="subtle"
                                    size="lg"
                                    onClick={() => {
                                        handleIpAddressDelete(index);
                                    }}
                                    icon={<Icon icon={"trash-o"} />}
                                    disabled={
                                        !isCheckboxEnabled ||
                                        (allowedIpAddresses.length === 1 &&
                                            isSftpEnabled)
                                    }
                                />
                            </>
                        </td>
                    </tr>
                </>
            );
        }
    );

    return (
        <>
            {isSftpExpanded && (
                <div className="sftp-id-addresses__container">
                    <div className="sftp-id-addresses__header">
                        <ControlLabel
                            className={cn(
                                "feature-groups__label",
                                "sftp-id-addresses__label"
                            )}
                        >
                            {t(
                                "tenantConfig.featureConfigurations.sftpEnabled.ipAddressesTitle"
                            )}
                            <HelpBlock
                                className={"general-configurations__help"}
                                tooltip
                            >
                                {t(
                                    "tenantConfig.featureConfigurations.sftpEnabled.ipAddressesDescription"
                                )}
                            </HelpBlock>
                        </ControlLabel>
                        <Button
                            className="sftp-id-addresses__container__add-more-btn"
                            type="submit"
                            appearance="ghost"
                            onClick={handleAddIpAddress}
                            disabled={!isCheckboxEnabled}
                        >
                            {t(
                                "tenantConfig.featureConfigurations.sftpEnabled.table.addNew"
                            )}
                        </Button>
                    </div>
                    <>
                        {allowedIpAddresses.length ? (
                            <table className={"sftp-id-addresses__table"}>
                                <thead>
                                    <tr
                                        className={
                                            "sftp-id-addresses__table-header"
                                        }
                                    >
                                        <th
                                            className={
                                                "sftp-id-addresses__table-cell"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.featureConfigurations.sftpEnabled.table.header"
                                            )}
                                        </th>
                                        <th
                                            className={
                                                "sftp-id-addresses__table-cell"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.featureConfigurations.sftpEnabled.table.actionsHeader"
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>
                        ) : (
                            <div className={"sftp-id-addresses__table-empty"}>
                                {t(
                                    "tenantConfig.featureConfigurations.sftpEnabled.noIpAddresses"
                                )}
                            </div>
                        )}
                    </>
                    {!isSftpEnabled && (
                        <ControlLabel className={"feature-groups__label"}>
                            {t(
                                "tenantConfig.featureConfigurations.sftpEnabled.sftpEnablingCondition"
                            )}
                        </ControlLabel>
                    )}
                </div>
            )}
        </>
    );
};

export default SftpIpAddresses;
