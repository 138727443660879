import { API } from "utils/api-config";
import { ITenantGeneralConfigurations } from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { defaultErrorResponse } from "../../defaults";
import { CancelTokenSource } from "axios";
import { handleApiResponse } from "utils/api-request-cancellation";

export const loadConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantGeneralConfigurations | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }

    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/general-configs`,
        { cancelToken: cancelTokenSource?.token }
    );
    return handleApiResponse(response, cancelTokenSource);
};

export const updateConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    tenantConfigs: ITenantGeneralConfigurations
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker || !tenantConfigs) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantMoniker}/general-configs`;
    const response = await API.put(routeUrl, tenantConfigs);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const refreshSearchIndex = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `/environments/${envMoniker}/tenants/${tenantId}/general-configs/refresh-search-index`;
    const response = await API.post(routeUrl);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateSearchIndex = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `/environments/${envMoniker}/tenants/${tenantId}/general-configs/update-search-index`;
    const response = await API.post(routeUrl);

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
