import React, { useState } from "react";
import { Button, ButtonProps } from "rsuite";
import {
    ITenantDeleteWorkflow,
    ITenantFeatureSettingWorkFlow,
    IWorkflowBase,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    isEveryWorkflowStepCompleted,
    isWorkflowFailed,
    isWorkflowWaitingForApprove,
} from "utils/tenant-workflows/tenant-workflows-helper";
import { useTranslation } from "react-i18next";
import TenantFeatureProgressModalContainer from "../TenantFeature/TenantFeatureProgressModal/TenantFeatureProgressModalContainer";
import TenantDeleteProgressModal from "../TenantDelete/TenantDeleteProgressModal";
import cn from "classnames";

import css from "./ProcessModalButton.module.css";

interface IComponentProps extends ButtonProps {
    workflow: IWorkflowBase;
    workflowType: WorkflowModalType;
    header?: React.ReactNode;
    footer?: React.ReactNode;
}

const ProcessModalButton: React.FC<IComponentProps> = ({
    workflow,
    className,
    workflowType,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    const handleClose = () => {
        setIsModalOpened(false);
    };

    const getButtonText = (workflow: IWorkflowBase) => {
        if (isEveryWorkflowStepCompleted(workflow.steps))
            return t(
                "tenantFeatureSetting.checkbox.progressModalButton.history"
            );
        else if (isWorkflowWaitingForApprove(workflow)) {
            return t(
                "tenantFeatureSetting.checkbox.progressModalButton.isWaiting"
            );
        } else
            return t(
                "tenantFeatureSetting.checkbox.progressModalButton.progress"
            );
    };

    const getButtonColor = (workflow: IWorkflowBase) => {
        if (isWorkflowFailed(workflow)) return css.error;
        else if (isWorkflowWaitingForApprove(workflow)) {
            return css.warning;
        } else return css.normal;
    };

    const getProgressModal = (workflowType: WorkflowModalType) => {
        switch (workflowType) {
            case WorkflowModalType.Feature:
                return (
                    <TenantFeatureProgressModalContainer
                        isModalOpened={isModalOpened}
                        onClose={handleClose}
                        workflow={workflow as ITenantFeatureSettingWorkFlow}
                    />
                );
            case WorkflowModalType.TenantDeletion:
                return (
                    <TenantDeleteProgressModal
                        isModalOpened={isModalOpened}
                        onClose={handleClose}
                        workflow={workflow as ITenantDeleteWorkflow}
                    />
                );
            default:
                return <></>;
        }
    };

    const buttonColor = getButtonColor(workflow);
    const buttonText = getButtonText(workflow);
    const progressModal = getProgressModal(workflowType);

    return (
        <>
            {progressModal}
            <Button
                className={cn(css.processModalButton, className, buttonColor)}
                appearance="link"
                onClick={(e) => {
                    setIsModalOpened(true);
                    e.preventDefault();
                }}
            >
                {buttonText}
            </Button>
        </>
    );
};

export default ProcessModalButton;
