import i18n from "../i18n";
import { TenantFeatureSettingWorkFlowType } from "./tenant-workflows-types";

export const cefTenantFeatureSettingsNames = {
    vendor: "Vendor",
    purchaseOrder: "PurchaseOrder",
    productReceipt: "ProductReceipt",
    bankAccount: "BankAccount",
    paymentTerm: "PaymentTerm",
    cashDiscCode: "CashDiscCode",
    miscCharge: "MiscCharge",
    projectActivities: "ProjectActivities",
    salesTax: "SalesTax",
    mainDimension: "MainDimension",
    projectDefaultLineProperties: "ProjectDefaultLineProperties",
    projectLineProperties: "ProjectLineProperties",
    paymentInformation: "PaymentInformation",
};

export const tenantFeatureSettingDefinitions = {
    [TenantFeatureSettingWorkFlowType.DocumentsTransfer]: {
        urlParameterValue: "DocumentsTransfer",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.documentsTransfer"
        ),
    },
    [TenantFeatureSettingWorkFlowType.EnableSftp]: {
        urlParameterValue: "EnableSFTP",
        displayName: i18n.t("tenantFeatureSetting.workFlowName.enableSftp"),
    },
    [TenantFeatureSettingWorkFlowType.D365RoleDooapRequiredToAccessDooap]: {
        urlParameterValue: "D365RoleDooapRequiredToAccessDooap",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.d365RoleDooapRequiredToAccessDooap"
        ),
    },
    [TenantFeatureSettingWorkFlowType.KeyVaultAppRegistrationSecretRotation]: {
        urlParameterValue: "KeyVaultAppRegistrationSecretRotation",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.keyVaultAppRegistrationSecretRotation"
        ),
    },
    [TenantFeatureSettingWorkFlowType.PowerBiWorkspaceDeployment]: {
        urlParameterValue: "PowerBiWorkspaceDeployment",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.powerBiWorkspaceDeployment"
        ),
    },
    [TenantFeatureSettingWorkFlowType.DatabaseAlertRuleCreation]: {
        urlParameterValue: "DatabaseAlertRuleCreation",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.databaseAlertRuleCreation"
        ),
    },
    [TenantFeatureSettingWorkFlowType.VendorChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.vendor,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.vendorChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.PurchaseOrderChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.purchaseOrder,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.purchaseOrderChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.ProductReceiptChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.productReceipt,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.productReceiptChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.PaymentInformationChangeEntityFramework]:
        {
            urlParameterValue: cefTenantFeatureSettingsNames.paymentInformation,
            displayName: i18n.t(
                "tenantFeatureSetting.workFlowName.paymentInformationChangeEntityFramework"
            ),
        },
    [TenantFeatureSettingWorkFlowType.BankAccountChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.bankAccount,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.bankAccountChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.PaymentTermChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.paymentTerm,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.paymentTermChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.CashDiscCodeChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.cashDiscCode,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.cashDiscCodeChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.MiscChargeChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.miscCharge,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.miscChargeChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.ProjectActivitiesChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.projectActivities,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.projectActivitiesChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.SalesTaxChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.salesTax,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.salesTaxChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.MainDimensionChangeEntityFramework]: {
        urlParameterValue: cefTenantFeatureSettingsNames.mainDimension,
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.mainDimensionValuesChangeEntityFramework"
        ),
    },
    [TenantFeatureSettingWorkFlowType.ProjectDefaultLinePropertiesChangeEntityFramework]:
        {
            urlParameterValue:
                cefTenantFeatureSettingsNames.projectDefaultLineProperties,
            displayName: i18n.t(
                "tenantFeatureSetting.workFlowName.projectDefaultLinePropertiesChangeEntityFramework"
            ),
        },
    [TenantFeatureSettingWorkFlowType.ProjectLinePropertiesChangeEntityFramework]:
        {
            urlParameterValue:
                cefTenantFeatureSettingsNames.projectLineProperties,
            displayName: i18n.t(
                "tenantFeatureSetting.workFlowName.projectLinePropertiesChangeEntityFramework"
            ),
        },
    [TenantFeatureSettingWorkFlowType.CodingPrediction]: {
        urlParameterValue: "CodingPrediction",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.mlCodingPrediction"
        ),
    },
    [TenantFeatureSettingWorkFlowType.SalesTaxPrediction]: {
        urlParameterValue: "SalesTaxPrediction",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.mlSalesTaxPrediction"
        ),
    },
    [TenantFeatureSettingWorkFlowType.WorkflowPrediction]: {
        urlParameterValue: "WorkflowPrediction",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.mlWorkflowPrediction"
        ),
    },
    [TenantFeatureSettingWorkFlowType.CaptureMailboxesCreation]: {
        urlParameterValue: "CaptureMailboxesCreation",
        displayName: i18n.t(
            "tenantFeatureSetting.workFlowName.captureMailboxesCreation"
        ),
    },
};

export const axImportNameToWorkflowTypeMapping: {
    [key: string]: TenantFeatureSettingWorkFlowType;
} = {
    [cefTenantFeatureSettingsNames.productReceipt]:
        TenantFeatureSettingWorkFlowType.ProductReceiptChangeEntityFramework,
    [cefTenantFeatureSettingsNames.purchaseOrder]:
        TenantFeatureSettingWorkFlowType.PurchaseOrderChangeEntityFramework,
    [cefTenantFeatureSettingsNames.vendor]:
        TenantFeatureSettingWorkFlowType.VendorChangeEntityFramework,
    [cefTenantFeatureSettingsNames.paymentInformation]:
        TenantFeatureSettingWorkFlowType.PaymentInformationChangeEntityFramework,
    [cefTenantFeatureSettingsNames.bankAccount]:
        TenantFeatureSettingWorkFlowType.BankAccountChangeEntityFramework,
    [cefTenantFeatureSettingsNames.paymentTerm]:
        TenantFeatureSettingWorkFlowType.PaymentTermChangeEntityFramework,
    [cefTenantFeatureSettingsNames.cashDiscCode]:
        TenantFeatureSettingWorkFlowType.CashDiscCodeChangeEntityFramework,
    [cefTenantFeatureSettingsNames.miscCharge]:
        TenantFeatureSettingWorkFlowType.MiscChargeChangeEntityFramework,
    [cefTenantFeatureSettingsNames.projectActivities]:
        TenantFeatureSettingWorkFlowType.ProjectActivitiesChangeEntityFramework,
    [cefTenantFeatureSettingsNames.salesTax]:
        TenantFeatureSettingWorkFlowType.SalesTaxChangeEntityFramework,
    [cefTenantFeatureSettingsNames.mainDimension]:
        TenantFeatureSettingWorkFlowType.MainDimensionChangeEntityFramework,
    [cefTenantFeatureSettingsNames.projectDefaultLineProperties]:
        TenantFeatureSettingWorkFlowType.ProjectDefaultLinePropertiesChangeEntityFramework,
    [cefTenantFeatureSettingsNames.projectLineProperties]:
        TenantFeatureSettingWorkFlowType.ProjectLinePropertiesChangeEntityFramework,
};
