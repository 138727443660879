import { defaultErrorResponse } from "api/defaults";
import { CancelTokenSource } from "axios";
import {
    IErpConnectionInfo,
    AuthenticationMethod,
    IErpThrottlingPriority,
    ICredentialsValidation,
    IErpInfo,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { API } from "utils/api-config";
import { handleApiResponse } from "utils/api-request-cancellation";

export const loadErpConnectionInfo = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IErpConnectionInfo | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/connection-info`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const loadAuthenticationMethods = async (
    environmentMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[AuthenticationMethod[] | null, number]> => {
    const response = await API.get(
        `environments/${environmentMoniker}/tenants/${tenantId}/erp/authentication-methods`,
        { cancelToken: cancelTokenSource?.token }
    );

    return handleApiResponse(response, cancelTokenSource);
};

export const loadErpSecretsInfo = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IErpInfo | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/secrets-info`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const loadTenantThrottlingPriorities = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IErpThrottlingPriority[] | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/throttling-priorities`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const loadTenantLinkedThrottlingPriority = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[IErpThrottlingPriority | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/throttling-priorities/linked`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const loadTenantDefaultKeyVaultCompany = async (
    envMoniker: string,
    tenantId: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/default-key-vault-company`;
    const response = await API.get(routeUrl, {
        cancelToken: cancelTokenSource?.token,
    });

    return handleApiResponse(response, cancelTokenSource);
};

export const updateErpConnectionInfo = async (
    envMoniker: string,
    tenantId: string,
    connectionInfo: IErpConnectionInfo | null
): Promise<[ICredentialsValidation | null, number]> => {
    if (!envMoniker || !tenantId || !connectionInfo) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantId}/erp/connection-info`,
        connectionInfo
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateDefaultKeyVaultCompany = async (
    envMoniker: string,
    tenantId: string,
    defaultKeyVaultCompanyId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const defaultKeyVaultCompany = {
        defaultKeyVaultCompany: defaultKeyVaultCompanyId,
    };

    const routeUrl = `environments/${envMoniker}/tenants/${tenantId}/erp/default-key-vault-company`;
    const response = await API.put(routeUrl, defaultKeyVaultCompany);
    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const updateTenantLinkedThrottlingPriority = async (
    envMoniker: string,
    tenantId: string,
    erpThrottlingPriority: IErpThrottlingPriority
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId || !erpThrottlingPriority) {
        return defaultErrorResponse;
    }

    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantId}/erp/throttling-priorities/linked`,
        erpThrottlingPriority
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const clearKeyVaultParametersExceptDefaultCompany = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${envMoniker}/tenants/${tenantId}/erp/clear-key-vault-parameters`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};

export const resetDooapUrl = async (
    envMoniker: string,
    tenantId: string
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantId) {
        return defaultErrorResponse;
    }

    const response = await API.post(
        `environments/${envMoniker}/tenants/${tenantId}/erp/reset-dooap-url`
    );

    if (!response) {
        return defaultErrorResponse;
    }

    return [response.data, response.status];
};
