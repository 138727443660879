import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./CaptureMailboxes.less";
import { Icon, Loader, Table } from "rsuite";
import {
    IMailbox,
    IMailboxErrorLog,
} from "redux/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";
import {
    loadMailboxesCreationErrorLogs,
    loadTenantCaptureMailboxes,
} from "api/environments/tenants/tenant-capture-mailboxes-api";
import { getErrorMessage } from "api/defaults";
import constants from "utils/constants";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios from "axios";
import useRefreshPage from "hooks/useRefreshPage";
import MailboxesCreationContainer from "./MailboxesCreation/MailboxesCreationContainer";
import SignalRTenantFeatureSetting from "components/WorkflowLog/TenantFeature/SignalRTenantFeatureSetting";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import { useParams } from "react-router-dom";
import { getTenantFeatureSettingWorkFlow } from "api/environments/tenants/tenant-feature-settings-api";
import usePreviousState from "hooks/usePreviousState";
import MailboxesErrorLogModal from "./MailboxesErrorLogModal/MailboxesErrorLogModal";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isLoaderVisible: boolean;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
}

const CaptureMailboxes: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isLoaderVisible,
    showLoader,
    showError,
}: ComponentProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);
    const cancelTokenSource = useRequestCancellation();
    const params: Record<string, string> = useParams();
    const { tenantId } = params;

    const [tableHeight, setTableHeight] = useState<number>();
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortType, setSortType] = useState();
    const [mailboxes, setMailboxes] = useState<IMailbox[]>([]);
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
    const [previousSettingWorkFlow, settingWorkFlow, setSettingWorkFlow] =
        usePreviousState<ITenantFeatureSettingWorkFlow | null>(null);
    const [areMailboxesCreating, setAreMailboxesCreating] = useState(false);
    const [mailboxesErrorLogs, setMailboxesErrorLogs] = useState<
        IMailboxErrorLog[]
    >([]);
    const [isErrorLogOpened, setErrorLogOpened] = useState<boolean>(false);

    const fetchData = () => {
        showLoader(true);
        Promise.all([
            getTenantFeatureSettingWorkFlow(
                envMoniker,
                tenantMoniker,
                TenantFeatureSettingWorkFlowType.CaptureMailboxesCreation,
                cancelTokenSource
            )
                .then(([workFlows, _]) => {
                    setSettingWorkFlow(
                        workFlows?.find(
                            (workflow) =>
                                workflow.type ===
                                TenantFeatureSettingWorkFlowType.CaptureMailboxesCreation
                        ) ?? null
                    );
                })
                .catch((error: Error) => {
                    if (!Axios.isCancel(error)) {
                        showError(error.message);
                    }
                }),
            loadTenantCaptureMailboxes(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setMailboxes(payload);
                    } else {
                        showError(getErrorMessage(String(payload), status));
                    }
                })
                .catch((error: Error) => {
                    if (!Axios.isCancel(error)) {
                        showError(error.message);
                    }
                }),
            loadMailboxesCreationErrorLogs(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setMailboxesErrorLogs(payload);
                    } else {
                        showError(getErrorMessage(String(payload), status));
                    }
                })
                .catch((error: Error) => {
                    if (!Axios.isCancel(error)) {
                        showError(error.message);
                    }
                }),
        ]).then(() => {
            showLoader(false);
        });
        setIsDataFetched(true);
    };

    const getData = () => {
        if (sortColumn && sortType) {
            return mailboxes.sort((a, b) => {
                let x = a[sortColumn as keyof IMailbox] || "-";
                let y = b[sortColumn as keyof IMailbox] || "-";
                x = typeof x === "string" ? x.toLowerCase() : x;
                y = typeof y === "string" ? y.toLowerCase() : y;

                return sortType === "asc" ? (x < y ? -1 : 1) : x < y ? 1 : -1;
            });
        }

        return mailboxes;
    };

    const updateTableHeight = () => {
        if (containerRef.current) {
            const tableHeight = containerRef.current.clientHeight * 0.8;
            setTableHeight(tableHeight);
        }
    };

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isDataFetched) {
            fetchData();
        }
    }, [envMoniker, tenantMoniker, isDataFetched]);

    useEffect(() => {
        if (!isLoaderVisible) {
            updateTableHeight();
        }
    }, [isLoaderVisible]);

    useEffect(() => {
        window.addEventListener("resize", updateTableHeight);
    }, []);

    useEffect(() => {
        if (settingWorkFlow) {
            setAreMailboxesCreating(
                settingWorkFlow.status === WorkflowStatus.Running
            );
        }
    }, [settingWorkFlow]);

    useEffect(() => {
        if (
            previousSettingWorkFlow &&
            settingWorkFlow &&
            previousSettingWorkFlow.status === WorkflowStatus.Running &&
            settingWorkFlow.status === WorkflowStatus.Success
        ) {
            fetchData();
        }
    }, [previousSettingWorkFlow, settingWorkFlow]);

    useRefreshPage(() => {
        setIsDataFetched(false);
    });

    return (
        <div ref={containerRef} className="capture-mailboxes__container">
            <SignalRTenantFeatureSetting
                tenantId={tenantId}
                updateTenantFeatureSettingWorkFlows={(
                    settingWorkflow: ITenantFeatureSettingWorkFlow
                ) => {
                    setSettingWorkFlow(settingWorkflow);
                }}
            >
                <h1>{t("tenantConfig.captureMailboxes.header")}</h1>
                <div className="capture-mailboxes__top-section">
                    <Loader
                        content={t(
                            `tenantConfig.captureMailboxes.loadingTitle`
                        )}
                        speed="slow"
                        size="sm"
                        className={
                            !areMailboxesCreating
                                ? "capture-mailboxes__top-section--hidden"
                                : ""
                        }
                    />
                    {!!mailboxesErrorLogs.length && (
                        <a
                            onClick={() => {
                                setErrorLogOpened(true);
                            }}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="capture-mailboxes__error-log"
                        >
                            {t(`tenantConfig.captureMailboxes.errorLogsTitle`)}
                        </a>
                    )}
                </div>

                <MailboxesErrorLogModal
                    errorLogs={mailboxesErrorLogs}
                    isOpened={isErrorLogOpened}
                    setIsOpened={setErrorLogOpened}
                />
                <MailboxesCreationContainer />
                {!isLoaderVisible && (
                    <>
                        <Table
                            data={getData()}
                            sortColumn={sortColumn}
                            sortType={sortType}
                            onSortColumn={handleSortColumn}
                            height={tableHeight}
                        >
                            <Table.Column flexGrow={4} fixed sortable>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.mailboxName`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="name">
                                    {(rowData: IMailbox) => {
                                        return (
                                            <PopoverCell
                                                dataKey="name"
                                                rowData={rowData}
                                            />
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={5} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.mailboxAddress`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="address">
                                    {(rowData: IMailbox) => {
                                        return (
                                            <PopoverCell
                                                dataKey="address"
                                                rowData={rowData}
                                            />
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column flexGrow={2} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.companyAssignment`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="assignedCompanyRemoteId">
                                    {(rowData: IMailbox) => {
                                        return (
                                            <div>
                                                {rowData.assignedCompanyRemoteId ??
                                                    "-"}
                                            </div>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                            <Table.Column width={100} fixed="right">
                                <Table.HeaderCell>
                                    {t(
                                        `tenantConfig.captureMailboxes.table.hyperlink`
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell
                                    dataKey="hyperlink"
                                    className="list_cell--centralized"
                                >
                                    {(rowData: IMailbox) => {
                                        return (
                                            <a
                                                href={`${constants.mailboxUrl}${rowData.address}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="list__link"
                                            >
                                                <Icon
                                                    icon={"external-link"}
                                                ></Icon>
                                            </a>
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        </Table>
                    </>
                )}
            </SignalRTenantFeatureSetting>
        </div>
    );
};

export default CaptureMailboxes;
