import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ControlLabel, Radio, RadioGroup } from "rsuite";
import {
    IAxIntegrationApproveData,
    IntegrationFormType,
    IEnrollCache,
    IEnrollStep,
    StepStatus,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import {
    Area,
    IDooapAppRegistration,
} from "redux/environments/environment-types";
import SecretIntegrationForm from "components/Environments/Tenants/EnrollTenant/StepFrame/IntegrationInformation/SecretIntegrationForm";
import PasswordIntegrationForm from "components/Environments/Tenants/EnrollTenant/StepFrame/IntegrationInformation/PasswordIntegrationForm";
import styles from "./IntegrationInformation.module.css";
import constants from "utils/constants";
import {
    CustomerType,
    ICustomerDetails,
} from "redux/customers/customer-details/customer-details-types";
import useRefreshPage from "hooks/useRefreshPage";

const d365IntegrationInfoDefault = {
    username: "",
    password: "",
    axUrl: "",
    secretExpirationDate: null,
    tokenUrl: "",
    axAuthority: "",
    axClientId: "",
    axClientSecret: "",
    azureAdResource: "",
};

interface IComponentProps {
    activeStep: IEnrollStep | null;
    environmentMoniker: string;
    customerDetails: ICustomerDetails | null;
    appRegistrations: IDooapAppRegistration[];
    enrollId: string;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
    fetchCustomerDetails: (customerId: string) => Promise<void>;
    fetchDooapAppRegistrations: (envMoniker: string) => void;
    setActiveStepData: (data: string) => void;
    setIsStepFormValid: Dispatch<SetStateAction<() => boolean>>;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}
const IntegrationInformation: React.FC<IComponentProps> = ({
    activeStep,
    environmentMoniker,
    appRegistrations,
    enrollId,
    customerDetails,
    fetchEnrollCache,
    fetchCustomerDetails,
    fetchDooapAppRegistrations,
    setActiveStepData,
    setIsStepFormValid,
    setIsUnsavedChangeAvailable,
    showLoader,
}: IComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<HTMLFormElement>();
    const [selectedType, setSelectedType] = useState<IntegrationFormType>(
        IntegrationFormType.Password
    );
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [d365AppRegistration, setD365AppRegistration] =
        useState<IDooapAppRegistration | null>(null);

    const [d365IntegrationInfo, setD365IntegrationInfo] =
        useState<IAxIntegrationApproveData>(d365IntegrationInfoDefault);

    const [isFetched, setIsFetched] = useState<boolean>(false);

    const fetchData = (environmentMoniker: string, enrollId: string) => {
        if (
            environmentMoniker &&
            enrollId &&
            activeStep?.status !== StepStatus.Success
        ) {
            showLoader(true);
            Promise.all([
                fetchEnrollCache(environmentMoniker, enrollId).then(
                    ([payload, statusCode]) => {
                        if (payload && statusCode === constants.statusCode.OK) {
                            return fetchCustomerDetails(payload.customerId);
                        }
                    }
                ),
                fetchDooapAppRegistrations(environmentMoniker),
            ]).then(() => {
                showLoader(false);
                setIsFetched(true);
            });
        }
    };

    useEffect(() => {
        const appRegistration = appRegistrations.find(
            (registration) => registration.area === Area.D365
        );
        setD365AppRegistration(appRegistration as IDooapAppRegistration);
    }, [fetchDooapAppRegistrations, appRegistrations, customerDetails]);

    useRefreshPage(() => {
        if (enrollId) {
            if (activeStep?.status !== StepStatus.Success) {
                setSelectedType(IntegrationFormType.Password);
                setD365IntegrationInfo({
                    ...d365IntegrationInfo,
                    tokenUrl: constants.d365IntegrationAccountTokenUrl,
                    axClientId: d365AppRegistration?.clientId || "",
                });
            }
            if (formRef && formRef.current) {
                formRef.current.cleanErrors();
            }
        }
    });

    useEffect(() => {
        if (environmentMoniker && enrollId && !isFetched) {
            fetchData(environmentMoniker, enrollId);
        }
    }, [environmentMoniker, enrollId, isFetched]);

    useEffect(() => {
        if (activeStep) {
            try {
                const stepData: IAxIntegrationApproveData =
                    JSON.parse(activeStep.data || "") ||
                    d365IntegrationInfoDefault;

                if (activeStep?.status === StepStatus.Success) {
                    setIsFormDisabled(true);
                }

                if (stepData) {
                    setSelectedType(
                        stepData.azureAdResource.length
                            ? IntegrationFormType.Secret
                            : IntegrationFormType.Password
                    );
                    setD365IntegrationInfo(stepData);
                }
            } catch (error) {}
        }
    }, [activeStep?.status]);

    useEffect(() => {
        setIsStepFormValid(() => () => formRef?.current?.check() || false);
    }, [setIsStepFormValid, formRef]);

    useEffect(() => {
        d365IntegrationInfo.axAuthority &&
            formRef &&
            formRef.current &&
            formRef.current.checkForField("axAuthority");
    }, [d365IntegrationInfo.axAuthority]);

    useEffect(() => {
        formRef && formRef.current && formRef.current.cleanErrors();
    }, []);

    useEffect(() => {
        formRef && formRef.current && formRef.current.cleanErrors();
    }, [selectedType]);

    useEffect(() => {
        if (activeStep?.status !== StepStatus.Success) {
            if (selectedType === IntegrationFormType.Password) {
                setD365IntegrationInfo((prevState) => ({
                    ...prevState,
                    tokenUrl:
                        prevState.tokenUrl ||
                        constants.d365IntegrationAccountTokenUrl,
                    axClientId:
                        prevState.axClientId ||
                        d365AppRegistration?.clientId ||
                        "",
                }));
            } else if (customerDetails?.aadIssuerId) {
                setD365IntegrationInfo((prevState) => ({
                    ...prevState,
                    tokenUrl:
                        prevState.tokenUrl ||
                        `${constants.d365IntegrationAccountTokenUrl}${customerDetails?.aadIssuerId}` ||
                        "",
                    azureAdResource:
                        prevState.azureAdResource || d365IntegrationInfo.axUrl,
                }));
            }
        }
    }, [
        activeStep?.status,
        d365AppRegistration,
        customerDetails,
        selectedType,
    ]);

    const handleFormTypeChange = (value: IntegrationFormType) => {
        const isPasswordAuthentication = value === IntegrationFormType.Password;
        setSelectedType(value);
        setD365IntegrationInfo({
            ...d365IntegrationInfo,
            axClientSecret: isPasswordAuthentication
                ? ""
                : d365IntegrationInfo.axClientSecret,
            secretExpirationDate: isPasswordAuthentication
                ? null
                : d365IntegrationInfo.secretExpirationDate,
            azureAdResource: isPasswordAuthentication
                ? ""
                : d365IntegrationInfo.azureAdResource,
        });
    };

    const handleFormChange = (data: any) => {
        const newValue = data as IAxIntegrationApproveData;

        if (newValue) {
            setD365IntegrationInfo(newValue);
            setActiveStepData(JSON.stringify(newValue));
            setIsUnsavedChangeAvailable(true);
        }
    };

    return (
        activeStep && (
            <>
                <RadioGroup
                    name="enrollTypeList"
                    defaultValue={IntegrationFormType.Password}
                    value={selectedType}
                    onChange={(value) => {
                        handleFormTypeChange(value);
                    }}
                >
                    <ControlLabel className={styles.labelBold}>
                        {t("enrollTenant.enrollForm.enrollTypeTitle")}
                    </ControlLabel>
                    <Radio
                        disabled={isFormDisabled}
                        value={IntegrationFormType.Password}
                        className={styles.enrollType}
                    >
                        {t("enrollTenant.enrollForm.enrollTypePassword")}
                    </Radio>
                    <Radio
                        disabled={
                            isFormDisabled ||
                            customerDetails?.type === CustomerType.AX2012
                        }
                        value={IntegrationFormType.Secret}
                        className={styles.enrollType}
                    >
                        {t("enrollTenant.enrollForm.enrollTypeSecret")}
                    </Radio>
                </RadioGroup>
                {selectedType === IntegrationFormType.Password ? (
                    <PasswordIntegrationForm
                        d365IntegrationInfo={d365IntegrationInfo}
                        passwordFormRef={formRef}
                        onPasswordFormChange={(data) => handleFormChange(data)}
                        isFormDisabled={isFormDisabled}
                    />
                ) : (
                    <SecretIntegrationForm
                        envMoniker={environmentMoniker}
                        enrollId={enrollId}
                        d365IntegrationInfo={d365IntegrationInfo}
                        secretFormRef={formRef}
                        onSecretFormChange={(data) => handleFormChange(data)}
                        isFormDisabled={isFormDisabled}
                    />
                )}
            </>
        )
    );
};

export default IntegrationInformation;
