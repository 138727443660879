import * as React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Icon, Loader, Popover, Tooltip, Whisper } from "rsuite";
import Card from "components/Common/Card/Card";
import ModalContainer from "components/Common/Modal/ModalContainer";
import {
    D365RefreshParametersStatus,
    ITenant,
    ITenantPingInfo,
    TenantStatus,
} from "redux/environments/tenants/tenants-types";
import { getComplexStatusString, getPingStatus } from "utils/status-helper";
import { SyntheticEvent, useState } from "react";
import "./TenantCard.less";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import { useHistory } from "react-router-dom";
import constants from "utils/constants";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import { formatString } from "utils/regex-helper";
import Axios, { CancelTokenSource } from "axios";

interface ComponentProps {
    envName: string;
    envMoniker: string;
    tenantInfo: ITenant;
    pingInfo?: ITenantPingInfo;
    isD365ParametersRefreshAllowed: boolean;
    seqLogsLink: string;
    isUserPartner: boolean;
    isDeliverablesEnabled: boolean;
    onRoteTo: (event: any) => void;
    getErpVersion: (tenant: ITenant, value: string | null) => JSX.Element;
    getFetchStatusText: (fetchStatus: TenantStatus) => string;
    getPingStatusText: (pingInfo: ITenantPingInfo | undefined) => string;
    getRefreshParametersText: (
        status: number,
        message: string | null
    ) => string;
    pingTenantInD365: (
        envMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
}

const TenantCard: React.FC<ComponentProps> = ({
    envName,
    envMoniker,
    tenantInfo,
    pingInfo,
    isD365ParametersRefreshAllowed,
    seqLogsLink,
    isUserPartner,
    isDeliverablesEnabled,
    onRoteTo,
    getErpVersion,
    getFetchStatusText,
    getPingStatusText,
    getRefreshParametersText,
    pingTenantInD365,
}: ComponentProps) => {
    const [isOpen, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const routeHistory = useHistory();
    const isFetchStatusButtonEnabled = Boolean(
        tenantInfo.versionInfo.fetchStatus === TenantStatus.Error
    );
    const isRefreshParametersStatusButtonEnabled = Boolean(
        tenantInfo.versionInfo.refreshParametersStatus ===
            D365RefreshParametersStatus.Failed &&
            tenantInfo.versionInfo.refreshParametersErrorMessage
    );
    const [isFetchStatusClicked, setIsFetchStatusClicked] =
        React.useState(false);
    const [
        isRefreshParametersStatusClicked,
        setIsRefreshParametersStatusClicked,
    ] = React.useState(false);
    const isPingStatusButtonEnabled = Boolean(
        pingInfo && pingInfo.errorMessage
    );
    const [isPingStatusClicked, setIsPingStatusClicked] = React.useState(false);
    const [isPingLoading, setIsPingLoading] = useState<boolean>(false);
    const [copyFieldValueMessage, setCopyFieldValueMessage] = useState(
        t(
            "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
        )
    );
    const isCustomerNameTooltipVisible =
        tenantInfo.customerName !== null
            ? tenantInfo.customerName.length > constants.tenantCardNameLimit
            : false;
    const isTenantNameTooltipVisible =
        tenantInfo.versionInfo.name.length > constants.tenantCardHeaderLimit;
    const partners = tenantInfo.partners?.join(", ");
    const isPartnersTooltipVisible = tenantInfo.partners
        ? partners.length > constants.tenantCardNameLimit
        : false;
    const isEnrollment =
        tenantInfo.versionInfo.fetchStatus === TenantStatus.Enroll;
    const isAddGuestTenantAddingAvailable = isEnrollment && tenantInfo.moniker;

    const cancelTokenSource = Axios.CancelToken.source();

    const hideStatusPopup = (event: SyntheticEvent<Element, Event>) => {
        event.stopPropagation();
        setOpen(false);
        setIsFetchStatusClicked(false);
        setIsPingStatusClicked(false);
        setIsRefreshParametersStatusClicked(false);
    };

    const showStatusPopup = (
        event: SyntheticEvent,
        isStatusButtonEnabled: boolean
    ) => {
        event.stopPropagation();
        isStatusButtonEnabled ? setOpen(true) : setOpen(false);
    };

    const routeToGuestTenants = (event: SyntheticEvent) => {
        event.stopPropagation();

        routeHistory.push(
            `/environments/${envName}/enroll-tenant/${tenantInfo.enrollWorkflowId}/guest-tenants`
        );
    };

    const responseTime = tenantInfo?.versionInfo?.d365ResponseTime
        ? tenantInfo.versionInfo.d365ResponseTime + " ms"
        : t("tenantDashboard.tenantCard.unknown");

    const pingErrorMessage = pingInfo?.errorMessage ? (
        <div>
            <span>
                {`${t("tenantDashboard.statusPopup.pingErrorMessage")}: `}
            </span>
            <span className="tenant__modal__error-details">
                {pingInfo?.errorMessage}
            </span>
        </div>
    ) : (
        <></>
    );
    const fetchErrorMessage = tenantInfo.versionInfo.fetchErrorMessage ? (
        <div>
            <span>{`${t(
                "tenantDashboard.statusPopup.fetchErrorMessage"
            )}: `}</span>
            <span className="tenant__modal__error-details">
                {tenantInfo.versionInfo.fetchErrorMessage}
            </span>
        </div>
    ) : (
        <></>
    );

    const refreshParametersErrorMessage = tenantInfo.versionInfo
        .refreshParametersErrorMessage ? (
        <div>
            <span>{`${t(
                "tenantDashboard.statusPopup.refreshParametersErrorMessage"
            )}: `}</span>
            <span className="tenant__modal__error-details">
                {tenantInfo.versionInfo.refreshParametersErrorMessage}
            </span>
        </div>
    ) : (
        <></>
    );

    const header = isAddGuestTenantAddingAvailable ? (
        <div className="tenant-card__header">
            <h2 className="tenant-card__header-title">
                {tenantInfo.versionInfo.name ||
                    t("tenantDashboard.tenantCard.defaultTenantHeader")}
            </h2>

            <Button
                onClick={(event) => routeToGuestTenants(event)}
                className="tenant-card__header-title_guest_btn"
                appearance="primary"
            >
                {t("tenantDashboard.tenantCard.guestTenantBtn")}
            </Button>
        </div>
    ) : (
        <div className="tenant-card__header">
            <h2 className="tenant-card__header-title">
                {tenantInfo.versionInfo.name ||
                    t("tenantDashboard.tenantCard.defaultTenantHeader")}
            </h2>
        </div>
    );

    const d365ThrottlingPriority =
        tenantInfo.customerType !== CustomerType.D365 ? (
            <></>
        ) : (
            <>
                <span>
                    {t("tenantDashboard.tenantCard.d365ThrottlingPriority") +
                        ":"}
                </span>
                <span
                    className={classNames(
                        {
                            "tenant-card__version_status_warning":
                                tenantInfo.versionInfo
                                    .d365ThrottlingPriorityStatus ===
                                TenantStatus.Warning,
                        },
                        {
                            "tenant-card__version_status_error":
                                tenantInfo.versionInfo
                                    .d365ThrottlingPriorityStatus ===
                                TenantStatus.Error,
                        }
                    )}
                >
                    {tenantInfo.versionInfo.d365ThrottlingPriority ? (
                        tenantInfo.versionInfo.d365ThrottlingPriorityStatus !==
                        TenantStatus.Normal ? (
                            <>
                                <b>
                                    {
                                        tenantInfo.versionInfo
                                            .d365ThrottlingPriority
                                    }
                                </b>
                                <>
                                    {` (
                            ${tenantInfo.versionInfo.d365ThrottlingPriorityErrorMessage}
                            )`}
                                </>
                            </>
                        ) : (
                            <b>
                                {tenantInfo.versionInfo.d365ThrottlingPriority}
                            </b>
                        )
                    ) : (
                        t("tenantDashboard.tenantCard.unknown")
                    )}
                </span>
            </>
        );

    const getErrorMessage = (errorMessage: any) => {
        return (
            <div className="tenant__modal__error-details__container">
                {errorMessage}
            </div>
        );
    };

    const copyFieldValueTooltip = <Tooltip>{copyFieldValueMessage}</Tooltip>;

    const ping = async () => {
        setIsPingLoading(true);
        pingTenantInD365(
            envMoniker,
            tenantInfo.versionInfo.id,
            cancelTokenSource
        ).then(() => {
            setIsPingLoading(false);
        });
    };

    const handleCopyTenantId = () => {
        setCopyFieldValueMessage(
            t("tenantConfig.generalConfigurationPage.copyFieldValue.copied")
        );
        navigator.clipboard.writeText(tenantInfo.versionInfo.id);
    };

    return (
        <div>
            <ModalContainer
                className="tenant__modal"
                enforceFocus={true}
                onHide={(event: any) => hideStatusPopup(event)}
                show={isOpen}
                footer={
                    <Button
                        className="tenant__modal__ok-btn"
                        onClick={(event) => hideStatusPopup(event)}
                    >
                        {t("tenantDashboard.statusPopup.buttonText")}
                    </Button>
                }
                title={
                    <span className="tenant__modal__title">
                        {t("tenantDashboard.statusPopup.statusInfo")}
                    </span>
                }
            >
                <div className={"tenant__modal__content"}>
                    <span>
                        {`${t("tenantDashboard.statusPopup.tenantName")}: ${
                            tenantInfo.versionInfo.name
                        }`}
                    </span>

                    {pingErrorMessage &&
                        isPingStatusClicked &&
                        getErrorMessage(pingErrorMessage)}

                    {fetchErrorMessage &&
                        isFetchStatusClicked &&
                        getErrorMessage(fetchErrorMessage)}

                    {refreshParametersErrorMessage &&
                        isRefreshParametersStatusClicked &&
                        getErrorMessage(refreshParametersErrorMessage)}
                </div>
            </ModalContainer>
            <Card
                status={getComplexStatusString(
                    tenantInfo.versionInfo.fetchStatus,
                    getPingStatus(pingInfo)
                )}
                onClick={onRoteTo}
                bordered
                shaded
                bodyFill
                className="tenant-card"
            >
                <div className="tenant-card__layout">
                    {isTenantNameTooltipVisible ? (
                        <Whisper
                            trigger="hover"
                            placement={"bottomStart"}
                            speaker={
                                <Popover
                                    className="tenant-card_popover"
                                    visible={true}
                                >
                                    {tenantInfo.versionInfo.name}
                                </Popover>
                            }
                        >
                            {header}
                        </Whisper>
                    ) : (
                        <>{header}</>
                    )}

                    {isEnrollment && (
                        <>
                            <span>
                                {t("tenantDashboard.tenantCard.status") + ":"}
                            </span>
                            <span>
                                <b>
                                    {getFetchStatusText(
                                        tenantInfo.versionInfo.fetchStatus
                                    )}
                                </b>
                            </span>
                        </>
                    )}

                    {!isEnrollment && (
                        <>
                            <span>
                                {t("tenantDashboard.tenantCard.customerName") +
                                    ":"}
                            </span>
                            <span className="tenant-card_name">
                                {isCustomerNameTooltipVisible ? (
                                    <Whisper
                                        trigger="hover"
                                        placement={"bottomStart"}
                                        speaker={
                                            <Popover
                                                className="tenant-card_popover"
                                                visible={true}
                                            >
                                                {tenantInfo.customerName}
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            {tenantInfo.customerName ??
                                                t(
                                                    "tenantDashboard.tenantCard.unknown"
                                                )}
                                        </span>
                                    </Whisper>
                                ) : (
                                    <span>
                                        {tenantInfo.customerName ??
                                            t(
                                                "tenantDashboard.tenantCard.unknown"
                                            )}
                                    </span>
                                )}
                            </span>
                            <span>
                                {t("tenantDashboard.tenantCard.partners") + ":"}
                            </span>
                            <span className="tenant-card_name">
                                {isPartnersTooltipVisible ? (
                                    <Whisper
                                        trigger="hover"
                                        placement={"bottomStart"}
                                        speaker={
                                            <Popover
                                                className="tenant-card_popover"
                                                visible={true}
                                            >
                                                {partners}
                                            </Popover>
                                        }
                                    >
                                        <span>{partners}</span>
                                    </Whisper>
                                ) : (
                                    <span>
                                        {tenantInfo.partners?.length
                                            ? partners
                                            : "-"}
                                    </span>
                                )}
                            </span>
                            <span>
                                {t("tenantDashboard.tenantCard.fetchStatus") +
                                    ":"}
                            </span>
                            {isFetchStatusButtonEnabled ? (
                                <button
                                    id={tenantInfo.versionInfo.id + "button"}
                                    className={classNames(
                                        "tenant__status-button",
                                        "tenant__status-button--enabled"
                                    )}
                                    onClick={(event: SyntheticEvent) => {
                                        showStatusPopup(
                                            event,
                                            isFetchStatusButtonEnabled
                                        );
                                        setIsFetchStatusClicked(true);
                                    }}
                                >
                                    {getFetchStatusText(
                                        tenantInfo.versionInfo.fetchStatus
                                    )}
                                </button>
                            ) : (
                                <span>
                                    <b>
                                        {getFetchStatusText(
                                            tenantInfo.versionInfo.fetchStatus
                                        )}
                                    </b>
                                </span>
                            )}
                            <span>
                                {t("tenantDashboard.tenantCard.pingStatus") +
                                    ":"}
                            </span>
                            {isPingLoading ? (
                                <Loader size="xs" />
                            ) : (
                                <div className="pingStatusRow">
                                    {isPingStatusButtonEnabled ? (
                                        <button
                                            id={
                                                tenantInfo.versionInfo.id +
                                                "button"
                                            }
                                            className={classNames(
                                                "tenant__status-button",
                                                "tenant__status-button--enabled"
                                            )}
                                            onClick={(
                                                event: SyntheticEvent
                                            ) => {
                                                showStatusPopup(
                                                    event,
                                                    isPingStatusButtonEnabled
                                                );
                                                setIsPingStatusClicked(true);
                                            }}
                                        >
                                            {getPingStatusText(pingInfo)}
                                        </button>
                                    ) : (
                                        <span>
                                            <b>{getPingStatusText(pingInfo)}</b>
                                        </span>
                                    )}
                                    <div style={{ marginLeft: "1rem" }}>
                                        <RefreshButton
                                            onClick={ping}
                                            disabled={false}
                                            tooltip={t(
                                                "tenantDashboard.tenantCard.pingButtonTitle"
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            {isD365ParametersRefreshAllowed && (
                                <span>
                                    {t(
                                        "tenantDashboard.tenantCard.refreshParametersStatus"
                                    ) + ":"}
                                </span>
                            )}
                            {isD365ParametersRefreshAllowed &&
                                (isRefreshParametersStatusButtonEnabled ? (
                                    <button
                                        id={
                                            tenantInfo.versionInfo.id + "button"
                                        }
                                        className={classNames(
                                            "tenant__status-button",
                                            "tenant__status-button--enabled"
                                        )}
                                        onClick={(event: SyntheticEvent) => {
                                            showStatusPopup(
                                                event,
                                                isRefreshParametersStatusButtonEnabled
                                            );
                                            setIsRefreshParametersStatusClicked(
                                                true
                                            );
                                        }}
                                    >
                                        {getRefreshParametersText(
                                            tenantInfo.versionInfo
                                                .refreshParametersStatus,
                                            tenantInfo.versionInfo
                                                .refreshParametersErrorMessage
                                        )}
                                    </button>
                                ) : (
                                    <span>
                                        <b>
                                            {getRefreshParametersText(
                                                tenantInfo.versionInfo
                                                    .refreshParametersStatus,
                                                tenantInfo.versionInfo
                                                    .refreshParametersErrorMessage
                                            )}
                                        </b>
                                    </span>
                                ))}
                            {d365ThrottlingPriority}
                            <span>
                                {t("tenantDashboard.tenantCard.dooapVersion") +
                                    ":"}
                            </span>
                            {getErpVersion(tenantInfo, null)}
                            <span>
                                {t(
                                    "tenantDashboard.tenantCard.applicationVersion"
                                ) + ":"}
                            </span>
                            <span>
                                {tenantInfo.versionInfo.applicationVersion ??
                                    t("tenantDashboard.tenantCard.unknown")}
                            </span>
                            <span>
                                {t(
                                    "tenantDashboard.tenantCard.platformVersion"
                                ) + ":"}
                            </span>
                            <span>
                                {tenantInfo.versionInfo.platformVersion ??
                                    t("tenantDashboard.tenantCard.unknown")}
                            </span>
                            <span>
                                {t("tenantDashboard.tenantCard.lastSyncTime") +
                                    ":"}
                            </span>
                            <span>
                                {tenantInfo.versionInfo.lastSyncTime ??
                                    t("tenantDashboard.tenantCard.unknown")}
                            </span>
                            <span>
                                {t(
                                    "tenantDashboard.tenantCard.d365ResponseTime"
                                ) + ":"}
                            </span>
                            <span>{responseTime}</span>
                            <span>
                                {t("tenantDashboard.tenantCard.id") + ":"}
                            </span>
                            <span>
                                {tenantInfo.versionInfo.id === null ||
                                tenantInfo.versionInfo.id === "" ? (
                                    t("tenantDashboard.tenantCard.unknown")
                                ) : (
                                    <>
                                        {tenantInfo.versionInfo.id}
                                        <Whisper
                                            placement="right"
                                            trigger="hover"
                                            speaker={copyFieldValueTooltip}
                                        >
                                            <Icon
                                                className="tenant-card__copy-button"
                                                icon="copy"
                                                onClick={(
                                                    event: SyntheticEvent
                                                ) => {
                                                    event.stopPropagation();
                                                    handleCopyTenantId();
                                                }}
                                                onMouseLeave={() =>
                                                    setCopyFieldValueMessage(
                                                        t(
                                                            "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
                                                        )
                                                    )
                                                }
                                            />
                                        </Whisper>
                                    </>
                                )}
                            </span>
                            {!isUserPartner && (
                                <>
                                    <span>
                                        {t("tenantDashboard.tenantCard.link") +
                                            ":"}
                                    </span>
                                    <span>
                                        {!tenantInfo.versionInfo.id ? (
                                            t(
                                                "tenantDashboard.tenantCard.unknown"
                                            )
                                        ) : (
                                            <a
                                                href={formatString(
                                                    constants.seqLogsFilter,
                                                    seqLogsLink,
                                                    tenantInfo.versionInfo.id
                                                )}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="list__link"
                                                onClick={(
                                                    event: SyntheticEvent
                                                ) => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                <Icon
                                                    icon={"external-link"}
                                                ></Icon>
                                            </a>
                                        )}
                                    </span>
                                </>
                            )}
                        </>
                    )}
                    <span>{t("tenantDashboard.tenantCard.moniker") + ":"}</span>
                    <span>
                        {!tenantInfo.moniker
                            ? t("tenantDashboard.tenantCard.unknown")
                            : tenantInfo.moniker}
                    </span>
                    {!isEnrollment && isDeliverablesEnabled && (
                        <>
                            <span>
                                {t(
                                    "tenantDashboard.tenantCard.packageDeliveryGroup"
                                ) + ":"}
                            </span>
                            <span>
                                {tenantInfo.packageDeliveryGroup ??
                                    t("tenantDashboard.tenantCard.unknown")}
                            </span>
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default TenantCard;
