import {
    SET_ENROLL_WORKFLOW,
    UPDATE_STEP_STATUS,
    SET_ORCHESTRATOR_INSTANCE_ID,
    SET_ACTIVE_STEP,
    SET_ACTIVE_STEP_DATA,
    SET_INITIAL_STATE,
    SET_ENROLL_CACHE,
    ISetEnrollCache,
    ISetActiveStep,
    IEnrollWorkflow,
    ISetActiveStepData,
    IStepActivityMessage,
    ISetEnrollTenant,
    IUpdateStepStatus,
    ISetOrchestratorInstanceId,
    IEnrollStep,
    IEnrollCache,
    ISetInitialState,
    IClearSecretActiveStepData,
    CLEAR_SECRET_ACTIVE_STEP_DATA,
    IAxIntegrationApproveData,
} from "./enroll-tenant-types";

export const setEnrollTenant = (
    enrollTenant: IEnrollWorkflow | null
): ISetEnrollTenant => ({
    type: SET_ENROLL_WORKFLOW,
    enrollTenant,
});

export const clearWorkflowData = (): ISetInitialState => ({
    type: SET_INITIAL_STATE,
});

export const setActiveStep = (
    activeStep: IEnrollStep | null
): ISetActiveStep => ({
    type: SET_ACTIVE_STEP,
    activeStep,
});

export const setEnrollCache = (enrollCache: IEnrollCache): ISetEnrollCache => ({
    type: SET_ENROLL_CACHE,
    enrollCache,
});

export const setActiveStepData = (data: string | null): ISetActiveStepData => ({
    type: SET_ACTIVE_STEP_DATA,
    data,
});

export const clearSecretActiveStepData = (
    data: IAxIntegrationApproveData
): IClearSecretActiveStepData => ({
    type: CLEAR_SECRET_ACTIVE_STEP_DATA,
    data,
});

export const updateStepStatus = (
    stepStatus: IStepActivityMessage
): IUpdateStepStatus => ({
    type: UPDATE_STEP_STATUS,
    stepStatus,
});

export const setOrchestratorInstanceId = (
    orchestratorInstanceId: string | null
): ISetOrchestratorInstanceId => ({
    type: SET_ORCHESTRATOR_INSTANCE_ID,
    orchestratorInstanceId,
});
