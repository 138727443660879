import React from "react";
import { Popover, Whisper } from "rsuite";
import "./PopoverCell.less";

interface ComponentProps {
    dataKey: string;
    rowData: any;
}

const PopoverCell: React.FC<ComponentProps> = ({
    dataKey,
    rowData,
}: ComponentProps) => {
    return (
        <Whisper
            trigger="hover"
            placement={"topStart"}
            delayShow={300}
            enterable
            speaker={
                <Popover className={"popover-cell"}>{rowData[dataKey]}</Popover>
            }
        >
            <div>{rowData[dataKey]}</div>
        </Whisper>
    );
};

export default PopoverCell;
