import { IHubConnection } from "utils/types";
import { IDooapUser } from "../tenant-users/tenant-users-types";
export const SET_ENROLL_WORKFLOW = "enrollTenant/SET_ENROLL_WORKFLOW";
export const UPDATE_STEP_STATUS = "enrollTenant/UPDATE_STEP_STATUS";
export const SET_ACTIVE_STEP = "enrollTenant/SET_ACTIVE_STEP";
export const SET_ACTIVE_STEP_DATA = "enrollTenant/SET_ACTIVE_STEP_DATA";
export const SET_ENROLL_CACHE = "enrollTenant/SET_ENROLL_CACHE";
export const SET_INITIAL_STATE = "enrollTenant/SET_INITIAL_STATE";
export const SET_ORCHESTRATOR_INSTANCE_ID =
    "enrollTenant/SET_ORCHESTRATOR_INSTANCE_ID";
export const CLEAR_SECRET_ACTIVE_STEP_DATA =
    "enrollTenant/CLEAR_SECRET_ACTIVE_STEP_DATA";

export enum StepType {
    Manual,
    Automated,
}

export enum StepStatus {
    NotStarted,
    Running,
    Failed,
    Success,
    Pending,
    Skipped,
}

export interface IEnrollStep {
    id: string;
    name: string;
    type: StepType;
    order: number;
    data: string | null;
    details: string;
    status: StepStatus;
    logs: string | null;
    // Related only to step 6. Otherwise, it should be null by default
    isFinalized: boolean | null;
}

export interface IEnrollStepData {
    actionDescription: string;
    actionMessage: string | null;
}

export interface IEnrollCache {
    customerId: string;
    tenantMoniker?: string | null;
    tenantId: string;
    tenantName: string;
    dooapInvoiceWebUri: string;
    authenticationMethod?: string | null;
}

export enum EnrollStatus {
    NotStarted = 0,
    InProgress,
    Failed,
    Success,
    Terminating,
}

export interface IEnrollWorkflow {
    id: string;
    steps: IEnrollStep[];
    status: EnrollStatus;
}

export interface IStepActivityMessage {
    Name: string;
    Status: StepStatus;
    Message: string;
    Data: any;
}

export interface IState {
    orchestratorInstanceId: string | null;
    enrollTenant: IEnrollWorkflow | null;
    hubInfo: IHubConnection | null;
    enrollCache: IEnrollCache | null;
    activeStep: IEnrollStep | null;
}

export interface ISetOrchestratorInstanceId {
    type: typeof SET_ORCHESTRATOR_INSTANCE_ID;
    orchestratorInstanceId: string | null;
}

export interface ISetInitialState {
    type: typeof SET_INITIAL_STATE;
}

export interface ISetActiveStep {
    type: typeof SET_ACTIVE_STEP;
    activeStep: IEnrollStep | null;
}

export interface IClearSecretActiveStepData {
    type: typeof CLEAR_SECRET_ACTIVE_STEP_DATA;
    data: IAxIntegrationApproveData;
}

export interface ISetEnrollCache {
    type: typeof SET_ENROLL_CACHE;
    enrollCache: IEnrollCache;
}

export interface ISetActiveStepData {
    type: typeof SET_ACTIVE_STEP_DATA;
    data: string | null;
}

export interface ISetEnrollTenant {
    type: typeof SET_ENROLL_WORKFLOW;
    enrollTenant: IEnrollWorkflow | null;
}

export interface IUpdateStepStatus {
    type: typeof UPDATE_STEP_STATUS;
    stepStatus: IStepActivityMessage;
}

export interface ICheckRightsStepApproveData {
    id: string;
    name: string;
    customer: {
        id: string;
        name: string;
    };
}

export interface IAxIntegrationApproveData {
    username: string;
    password: string;
    axUrl: string;
    secretExpirationDate: Date | null;
    tokenUrl: string;
    axAuthority: string;
    axClientId: string;
    axClientSecret: string;
    azureAdResource: string;
}

export enum IntegrationFormType {
    Password,
    Secret,
}

export interface IFirstAdminApproveData {
    isD365RoleDooapRequired: true;
    users: IDooapUser[];
}

export interface IAlterEnvironmentDBApproveData {
    productName: string;
    companyName: string;
    invoiceFolder: string;
    companyLogoUrl: string;
    splashLogoUrlWhite: string;
    splashLogoUrlBlack: string;
}

export interface IActionsWithScripts {
    action: string;
    scriptTitle: string | null;
    script: string[] | null;
}

export interface ITemplateWithLink {
    template: string;
    link: string | null;
}

export interface ISplashLogoLinks {
    splashLogoUrlWhite: string;
    splashLogoUrlBlack: string;
}

export interface ILogoLinks extends ISplashLogoLinks {
    companyLogoUrl: string;
}

export interface IScriptCommands {
    description: string;
    scripts: string[];
}

export interface ITemplateActionsWithScripts {
    actions: ITemplateWithLink[];
    scriptList: IScriptCommands[];
}

export interface IActionsWithSubactions {
    action: string;
    subactions: string[];
}

export interface IGuestTenantsData {
    addDooapGuestTenant: boolean;
}

export type InformationActions =
    | ISplashLogoLinks
    | string[]
    | IActionsWithScripts[]
    | ITemplateWithLink[]
    | ITemplateActionsWithScripts
    | IActionsWithSubactions[];

export type StepApproveDataTypes =
    | ICheckRightsStepApproveData
    | IAxIntegrationApproveData
    | IFirstAdminApproveData
    | IAlterEnvironmentDBApproveData
    | IGuestTenantsData
    | boolean;

export type Actions =
    | ISetActiveStep
    | ISetEnrollTenant
    | IUpdateStepStatus
    | ISetOrchestratorInstanceId
    | IClearSecretActiveStepData
    | ISetActiveStepData
    | ISetEnrollCache
    | ISetInitialState;
