import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar } from "rsuite";
import { useTranslation } from "react-i18next";
import {
    FailoverType,
    ITenantFeatureSettingWorkFlow,
    WorkflowStatus,
    WorkflowStepStatus,
    mlTenantFeatureSettingTypes,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    retryTenantFeatureSetting,
    handleFailedTenantFeatureSetting,
    retryCefTenantFeatureSetting,
    approveTenantFeatureSetting,
} from "api/environments/tenants/tenant-feature-settings-api";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import cefTenantFeatureSettingTypes from "utils/tenant-workflows/cef-tenant-workflows-types";

interface IComponentProps {
    isContinueButtonDisabled?: boolean;
    environmentMoniker: string;
    tenantMoniker: string;
    tenantFeatureSettingWorkFlow: ITenantFeatureSettingWorkFlow;
    onClose?: () => void;
}

const TenantFeatureProgressModalFooter: React.FC<IComponentProps> = ({
    isContinueButtonDisabled,
    environmentMoniker,
    tenantMoniker,
    tenantFeatureSettingWorkFlow,
    onClose,
}: IComponentProps) => {
    const { t } = useTranslation();
    const [isRefreshInvoked, setIsRefreshInvoked] = useState(false);
    const [isContinueInvoked, setIsContinueInvoked] = useState(false);

    const isSomeStepFailed = tenantFeatureSettingWorkFlow.steps.some(
        (step) => step.status === WorkflowStepStatus.Failed
    );
    const isWorkflowWaitingForApprove =
        tenantFeatureSettingWorkFlow.status === WorkflowStatus.WaitForApprove;

    const settingDefinition =
        tenantFeatureSettingDefinitions[tenantFeatureSettingWorkFlow.type] ??
        null;

    const isMlTrainingFailed =
        mlTenantFeatureSettingTypes.includes(
            tenantFeatureSettingWorkFlow.type
        ) &&
        tenantFeatureSettingWorkFlow.steps.some((step) => {
            return (
                (step.order === 3 || step.order === 4) &&
                step.status === WorkflowStepStatus.Failed
            );
        });

    useEffect(() => {
        if (isRefreshInvoked && !isSomeStepFailed) {
            setIsRefreshInvoked(false);
        }
        if (isContinueInvoked && !isSomeStepFailed) {
            setIsContinueInvoked(false);
        }
    }, [isSomeStepFailed]);

    const handleRetry = () => {
        setIsRefreshInvoked(true);

        const retryFunction = cefTenantFeatureSettingTypes.includes(
            tenantFeatureSettingWorkFlow.type
        )
            ? retryCefTenantFeatureSetting
            : isMlTrainingFailed
            ? () =>
                  handleFailedTenantFeatureSetting(
                      environmentMoniker,
                      tenantMoniker,
                      settingDefinition.urlParameterValue,
                      tenantFeatureSettingWorkFlow.id,
                      { failoverType: FailoverType.Retry }
                  )
            : retryTenantFeatureSetting;

        retryFunction(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            tenantFeatureSettingWorkFlow.id
        );
    };

    const handleContinue = () => {
        setIsContinueInvoked(true);

        const continueFunction = isMlTrainingFailed
            ? () =>
                  handleFailedTenantFeatureSetting(
                      environmentMoniker,
                      tenantMoniker,
                      settingDefinition.urlParameterValue,
                      tenantFeatureSettingWorkFlow.id,
                      { failoverType: FailoverType.Skip }
                  )
            : approveTenantFeatureSetting;

        continueFunction(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            tenantFeatureSettingWorkFlow.id
        );
    };

    return settingDefinition !== null ? (
        <ButtonToolbar className="custom-modal__button-toolbar">
            {isSomeStepFailed && (
                <Button
                    type="submit"
                    appearance="primary"
                    onClick={handleRetry}
                    loading={isRefreshInvoked}
                >
                    {t("tenantFeatureSetting.progressModal.button.retry")}
                </Button>
            )}
            {(isWorkflowWaitingForApprove ||
                (isSomeStepFailed && isMlTrainingFailed)) && (
                <Button
                    disabled={
                        isWorkflowWaitingForApprove && isContinueButtonDisabled
                    }
                    type="submit"
                    appearance="primary"
                    onClick={handleContinue}
                    loading={isContinueInvoked}
                >
                    {t("tenantFeatureSetting.progressModal.button.continue")}
                </Button>
            )}
            <Button
                appearance={isSomeStepFailed ? "ghost" : "primary"}
                onClick={onClose}
            >
                {t("tenantFeatureSetting.progressModal.button.close")}
            </Button>
        </ButtonToolbar>
    ) : null;
};

export default TenantFeatureProgressModalFooter;
