import { defaultErrorResponse } from "api/defaults";
import Axios, { CancelTokenSource } from "axios";
import {
    ITenantFeatureAppConfigurations,
    ITenantFeatureConfigurations,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { API } from "utils/api-config";

export const loadFeatureConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureConfigurations | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/configurations`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateFeatureConfigurations = async (
    envMoniker: string,
    tenantMoniker: string,
    featureConfigurations: ITenantFeatureConfigurations
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/configurations`,
        featureConfigurations
    );

    return [null, response.status];
};

export const loadFeatureAppConfigurationSettings = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[ITenantFeatureAppConfigurations | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/app-configurations`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};

export const updateFeatureAppConfigurationSettings = async (
    envMoniker: string,
    tenantMoniker: string,
    feattureAppConfigSettings: ITenantFeatureAppConfigurations
): Promise<[string | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.put(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/app-configurations`,
        feattureAppConfigSettings
    );

    return [response.data, response.status];
};

export const checkTenantDatabaseAlertRuleExistence = async (
    envMoniker: string,
    tenantMoniker: string,
    cancelTokenSource?: CancelTokenSource
): Promise<[boolean | null, number]> => {
    if (!envMoniker || !tenantMoniker) {
        return defaultErrorResponse;
    }
    const response = await API.get(
        `environments/${envMoniker}/tenants/${tenantMoniker}/features/alert-rule-exists`,
        { cancelToken: cancelTokenSource?.token }
    );

    if (!cancelTokenSource?.token.reason) {
        if (!response) {
            return defaultErrorResponse;
        }
        return [response.data, response.status];
    } else {
        throw new Axios.Cancel();
    }
};
