export const SET_CREATE_MAILBOXES_OPENED =
    "CaptureMailboxes/SET_CREATE_MAILBOXES_OPENED";

export interface IMailbox {
    name: string;
    address: string;
    assignedCompanyRemoteId: string | null;
}

export interface IMailboxCreation {
    companyId: string | null;
    companyRemoteId: string | null;
    mailboxName: string;
    mailboxAddress: string;
    mailboxExists: boolean;
    editing: boolean | null;
    checked: boolean;
}

export interface IGenericMailbox {
    genericMailboxName: string;
    genericMailboxAddress: string;
}

export enum MailboxCreationType {
    Generic,
    Company,
    All,
}

export interface IMailboxSave {
    name: string;
    address: string;
    assignedCompanyId: string | null;
}

export interface IMailboxErrorLog {
    mailboxAddress: string;
    failureReason: string;
}

export interface ValidationErrors {
    [key: string]: {
        mailboxName?: string;
        mailboxAddress?: string;
    };
}

export const mailboxFields = {
    mailboxName: "mailboxName",
    mailboxAddress: "mailboxAddress",
} as const;

export interface ISetCreateMailboxesOpened {
    type: typeof SET_CREATE_MAILBOXES_OPENED;
    isCreateMailboxesOpened: boolean;
}

export interface State {
    isCreateMailboxesOpened: boolean;
}

export type Actions = ISetCreateMailboxesOpened;
