import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import {
    Alert,
    Button,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    HelpBlock,
    InputPicker,
    Message,
    Schema,
} from "rsuite";
import SignalRTenantFeatureSetting from "components/WorkflowLog/TenantFeature/SignalRTenantFeatureSetting";
import useRefreshPage from "hooks/useRefreshPage";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import useInactiveTenant from "hooks/useInactiveTenant";
import constants from "utils/constants";
import {
    IErpConnectionInfo,
    AuthenticationMethod,
    AuthenticationType,
    IErpInfo,
    IErpThrottlingPriority,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { useParams } from "react-router-dom";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    isSettingWorkFlowUpdated,
    updateTenantSettingWorkFlow,
} from "utils/tenant-workflows/tenant-workflows-helper";
import { getTenantFeatureSettingWorkFlow } from "api/environments/tenants/tenant-feature-settings-api";
import usePreviousState from "hooks/usePreviousState";
import {
    loadErpConnectionInfo,
    loadAuthenticationMethods,
    loadErpSecretsInfo,
    loadTenantDefaultKeyVaultCompany,
    loadTenantLinkedThrottlingPriority,
    loadTenantThrottlingPriorities,
    updateErpConnectionInfo,
    updateDefaultKeyVaultCompany,
    updateTenantLinkedThrottlingPriority,
} from "api/environments/tenants/tenant-erp-configurations-api";
import { getErrorMessage } from "api/defaults";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import { loadCompanies } from "api/environments/tenants/tenant-companies-api";
import { ITenantCompany } from "redux/environments/tenants/tenant-companies/tenant-companies-types";
import { ItemDataType } from "rsuite/lib/@types/common";
import "../GeneralConfigurations/GeneralConfigurations.less";
import { FormInstance } from "rsuite/lib/Form/Form";
import { cloneDeep, template } from "lodash";
import ResetD365Parameters from "./ResetD365Parameters/ResetD365Parameters";
import ErpConnectionInfoContainer from "./ErpConnectionInfo/ErpConnectionInfoContainer";
import { validate } from "uuid";
import {
    checkIntegrationUserValidity,
    dismissDomainCheck,
} from "api/environments/tenants/tenant-users-api";
import Axios from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";
import ClientSecretFormGroup from "./ClientSecretFormGroup/ClientSecretFormGroup";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isFetched: boolean;
    authenticationMethodDescription: string;
    customerType: CustomerType | null;
    showLoader: (isVisible: boolean) => void;
    showError: (errorMessage: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setIsTenantFetched: (isFetched: boolean) => void;
}

const initalErpSecretsInfo = {
    appRegSecretExpiration: {
        secretExpirationTimeInDays: null,
        secretExpirationStatus: null,
        secretRotationDate: null,
    },
    axSecretExpiration: {
        secretExpirationTimeInDays: null,
        secretExpirationStatus: null,
        secretRotationDate: null,
    },
};

const emptyDefaultKeyVaultCompanyDropdownItem = {
    name: "",
    remoteId: "",
};

const ErpConfigurations: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isFetched,
    authenticationMethodDescription,
    customerType,
    showLoader,
    showError,
    setIsUnsavedChangeAvailable,
    setIsTenantFetched,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const params: Record<string, string> = useParams();
    const { tenantId } = params;
    const formRef = useRef<FormInstance>(null);
    const [
        previousFeatureSettingWorkFlows,
        featureSettingWorkFlows,
        setFeatureSettingWorkFlows,
    ] = usePreviousState<ITenantFeatureSettingWorkFlow[] | null>(null);

    const [authMethods, setAuthMethods] = useState<AuthenticationMethod[]>([]);
    const [erpConnectionInfo, setErpConnectionInfo] =
        useState<IErpConnectionInfo | null>(null);
    const defaultAuthenticationMethod = {
        authenticationType: erpConnectionInfo?.clientSecret
            ? AuthenticationType.ClientSecret
            : AuthenticationType.UsernamePassword,
        authenticationDescription: authenticationMethodDescription,
    };
    const [authenticationMethod, setAuthenticationMethod] =
        useState<AuthenticationMethod>(defaultAuthenticationMethod);
    const [isConnectionInfoFetched, setConnectionInfoFetched] =
        useState<boolean>(false);
    const [isConnectionInfoChanged, setConnectionInfoChanged] =
        useState<boolean>(false);
    const [isPasswordAuthentication, setIsPasswordAuthentication] =
        useState<boolean>(
            defaultAuthenticationMethod.authenticationType ===
                AuthenticationType.UsernamePassword
        );

    const [defaultKeyVaultCompanyId, setDefaultKeyVaultCompanyId] = useState<
        string | null
    >(null);
    const [
        isDefaultKeyVaultCompanyIdFetchedSuccessfully,
        setIsDefaultKeyVaultCompanyIdFetchedSuccessfully,
    ] = useState<boolean | null>(null);
    const [isCompaniesDropdownDisabled, setIsCompaniesDropdownDisabled] =
        useState(true);
    const [companiesData, setCompaniesData] = useState<Record<string, any>[]>(
        []
    );
    const [
        defaultKeyVaultCompanyDropdownItem,
        setDefaultKeyVaultCompanyDropdownItem,
    ] = useState<ITenantCompany>(emptyDefaultKeyVaultCompanyDropdownItem);

    const [throttlingPriority, setThrottlingPriority] =
        useState<IErpThrottlingPriority | null>(null);
    const [
        isThrottlingPrioritiesFetchedSuccessfully,
        setIsThrottlingPrioritiesFetchedSuccessfully,
    ] = useState<boolean | null>(null);
    const [
        isThrottlingPriorityDropdownDisabled,
        setIsThrottlingPriorityDropdownDisabled,
    ] = useState(true);
    const [throttlingPrioritiesData, setThrottlingPrioritiesData] = useState<
        Record<string, any>[]
    >([]);
    const [throttlingPriorityDropdownItem, setThrottlingPriorityDropdownItem] =
        useState<IErpThrottlingPriority | null>(null);

    const [isIntegrationUserValid, setIsIntegrationUserValid] =
        useState<boolean>(true);
    const [isDismissWarningInProgress, setIsDismissWarningInProgress] =
        useState<boolean>(false);

    const [erpSecretsInfo, setErpSecretsInfo] =
        useState<IErpInfo>(initalErpSecretsInfo);

    const keyVaultAppRegistrationSecretRotationWorkflow =
        featureSettingWorkFlows?.find(
            (workflow) =>
                workflow.type ===
                TenantFeatureSettingWorkFlowType.KeyVaultAppRegistrationSecretRotation
        ) ?? null;

    const fetchErpConnectionInfo = async (
        envMoniker: string,
        tenantMoniker: string
    ) => {
        const [payload, statusCode] = await loadErpConnectionInfo(
            envMoniker,
            tenantMoniker
        );
        if (payload && statusCode === constants.statusCode.OK) {
            setErpConnectionInfo(payload);
        } else {
            const errorMessage = getErrorMessage(String(payload), statusCode);
            Alert.error(errorMessage, constants.alertDurationMsec);
        }
        setConnectionInfoFetched(true);
    };

    const fetchAuthenticationInfo = async (
        envMoniker: string,
        tenantId: string
    ): Promise<void> => {
        return loadAuthenticationMethods(
            envMoniker,
            tenantId,
            cancelTokenSource
        )
            .then(([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    setAuthMethods(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    Alert.error(errorMessage, constants.alertDurationMsec);
                }
            })
            .then(() => {
                return fetchErpConnectionInfo(envMoniker, tenantId);
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            });
    };

    const fetchDefaultKeyVaultCompany = async (
        envMoniker: string,
        tenantId: string
    ): Promise<void> => {
        return loadTenantDefaultKeyVaultCompany(
            envMoniker,
            tenantId,
            cancelTokenSource
        )
            .then(([loadedDefaultKeyVaultCompanyId, status]) => {
                if (status === constants.statusCode.OK) {
                    setIsDefaultKeyVaultCompanyIdFetchedSuccessfully(true);
                    setDefaultKeyVaultCompanyId(
                        loadedDefaultKeyVaultCompanyId || ""
                    );
                } else {
                    Alert.error(
                        t(
                            "tenantConfig.d365InformationPage.defaultKeyVaultCompany.fetchValueFailed"
                        ),
                        constants.alertDurationMsec
                    );
                }
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            });
    };

    const fetchTenantFeatureSettingWorkFlow = async (
        envMoniker: string,
        tenantMoniker: string
    ): Promise<void> => {
        return getTenantFeatureSettingWorkFlow(
            envMoniker,
            tenantMoniker,
            TenantFeatureSettingWorkFlowType.KeyVaultAppRegistrationSecretRotation,
            cancelTokenSource
        )
            .then(([workFlow, _]) => {
                setFeatureSettingWorkFlows(workFlow || []);
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            });
    };

    const fetchTenantThrottlingPriorities = async (
        envMoniker: string,
        tenantMoniker: string
    ): Promise<void> => {
        if (envMoniker && tenantMoniker) {
            Promise.all([
                loadTenantThrottlingPriorities(
                    envMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .then(([payload, statusCode]) => {
                        if (payload && statusCode === constants.statusCode.OK) {
                            setThrottlingPrioritiesData(
                                payload.map((throttlingPriority) => ({
                                    label:
                                        throttlingPriority.priorityName || "",
                                    value: { ...throttlingPriority },
                                }))
                            );
                            if (payload.length) {
                                setIsThrottlingPriorityDropdownDisabled(false);
                            }
                            setIsThrottlingPrioritiesFetchedSuccessfully(true);
                        } else {
                            Alert.error(
                                t(
                                    "tenantConfig.d365InformationPage.throttlingPriority.fetchValueFailed"
                                ),
                                constants.alertDurationMsec
                            );
                        }
                    })
                    .catch((error: Error) => {
                        if (!Axios.isCancel(error)) {
                            showError(error.message);
                        }
                    }),
                loadTenantLinkedThrottlingPriority(
                    envMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .then(([payload, statusCode]) => {
                        if (payload && statusCode === constants.statusCode.OK) {
                            setThrottlingPriorityDropdownItem(payload);
                        } else if (
                            statusCode === constants.statusCode.NotFound
                        ) {
                            return;
                        } else {
                            Alert.error(
                                t(
                                    "tenantConfig.d365InformationPage.throttlingPriority.fetchSelectedValueFailed"
                                ),
                                constants.alertDurationMsec
                            );
                        }
                    })
                    .catch((error: Error) => {
                        if (!Axios.isCancel(error)) {
                            showError(error.message);
                        }
                    }),
            ]);
        }
    };

    const fetchIsIntegrationUserDomainValid = async (
        envMoniker: string,
        tenantMoniker: string
    ): Promise<void> => {
        return checkIntegrationUserValidity(
            envMoniker,
            tenantMoniker,
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload !== null && status === constants.statusCode.OK) {
                    setIsIntegrationUserValid(payload);
                }
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            });
    };

    const fetchErpSecretsInfo = async (
        envMoniker: string,
        tenantId: string
    ): Promise<void> => {
        return loadErpSecretsInfo(envMoniker, tenantId, cancelTokenSource)
            .then(([payload, status]) => {
                if (payload !== null && status === constants.statusCode.OK) {
                    setErpSecretsInfo(payload);
                }
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    showError(error.message);
                }
            });
    };

    const fetchData = (envMoniker: string, tenantMoniker: string) => {
        if (envMoniker && tenantMoniker && tenantId) {
            showLoader(true);
            Promise.all([
                fetchTenantFeatureSettingWorkFlow(envMoniker, tenantMoniker),
                fetchAuthenticationInfo(envMoniker, tenantId),
                fetchDefaultKeyVaultCompany(envMoniker, tenantId),
                fetchIsIntegrationUserDomainValid(envMoniker, tenantMoniker),
                fetchErpSecretsInfo(envMoniker, tenantId),
            ]).then(() => {
                showLoader(false);
                setIsTenantFetched(true);
            });
        }
    };

    const formValue = {
        defaultKeyVaultCompany: defaultKeyVaultCompanyDropdownItem,
        throttlingPriority: throttlingPriorityDropdownItem,
        url: erpConnectionInfo?.url,
        authenticationMethod: authenticationMethod.authenticationType,
        username: erpConnectionInfo?.username,
        clientId: erpConnectionInfo?.clientId,
        secretExpirationDate: erpConnectionInfo?.secretExpirationDate,
        password: erpConnectionInfo?.password,
        clientSecret: erpConnectionInfo?.clientSecret,
    };

    const authClientSecretValidation = (validation: any) => {
        const updatedValidation = cloneDeep(validation);
        if (!isPasswordAuthentication) {
            return updatedValidation.isRequired(
                t("generalValidationMessages.isRequired")
            );
        }
        return updatedValidation;
    };

    const authPasswordValidation = (validation: any) => {
        const updatedValidation = cloneDeep(validation);
        if (isPasswordAuthentication) {
            return updatedValidation.isRequired(
                t("generalValidationMessages.isRequired")
            );
        }
        return updatedValidation;
    };

    const checkCompany = (value: ITenantCompany) => {
        return value.remoteId !== constants.notAcceptedDefaultKeyVaultCompany;
    };

    const { StringType, DateType, ObjectType } = Schema.Types;
    const formModel = Schema.Model({
        defaultKeyVaultCompany: ObjectType().addRule((value) => {
            return checkCompany(value);
        }, t("tenantConfig.d365InformationPage.defaultKeyVaultCompany.notAcceptedCompanyError")),
        url: StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .addRule((value) => {
                return constants.urlRegex.test(value);
            }, t("generalValidationMessages.isUrl")),
        username: StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .isEmail(t("generalValidationMessages.isEmail")),
        clientId: StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .addRule(
                (enteredValue: string) => validate(enteredValue),
                t("generalValidationMessages.isGuid")
            ),
        secretExpirationDate: authClientSecretValidation(DateType()),
        password: authPasswordValidation(StringType()),
        clientSecret: authClientSecretValidation(StringType()),
    });

    useEffect(() => {
        if (
            isSettingWorkFlowUpdated(
                TenantFeatureSettingWorkFlowType.KeyVaultAppRegistrationSecretRotation,
                previousFeatureSettingWorkFlows,
                featureSettingWorkFlows
            )
        ) {
            if (envMoniker && tenantId) {
                fetchErpSecretsInfo(envMoniker, tenantId);
                Alert.success(
                    t(
                        "tenantConfig.d365InformationPage.clientSecret.completedSuccessfully"
                    ),
                    constants.alertDurationMsec
                );
            }
        }
    }, [
        envMoniker,
        tenantId,
        previousFeatureSettingWorkFlows,
        featureSettingWorkFlows,
    ]);

    useEffect(() => {
        if (envMoniker && tenantMoniker && !isFetched) {
            fetchData(envMoniker, tenantMoniker);
        }
    }, [envMoniker, tenantMoniker, isFetched]);

    useEffect(() => {
        setIsTenantFetched(false);
    }, [setIsTenantFetched]);

    useEffect(() => {
        if (
            !throttlingPrioritiesData.length &&
            customerType === CustomerType.D365
        ) {
            fetchTenantThrottlingPriorities(envMoniker, tenantId);
        }
    }, [envMoniker, tenantId, throttlingPrioritiesData.length]);

    useEffect(() => {
        if (
            envMoniker &&
            tenantId &&
            companiesData.length &&
            defaultKeyVaultCompanyId
        ) {
            setIsCompaniesDropdownDisabled(true);
        }
    });

    useEffect(() => {
        if (
            envMoniker &&
            tenantMoniker &&
            !companiesData.length &&
            defaultKeyVaultCompanyId !== null
        ) {
            loadCompanies(envMoniker, tenantMoniker).then(
                ([payload, statusCode]) => {
                    if (payload && statusCode === constants.statusCode.OK) {
                        setCompaniesData(
                            payload.map((company) => ({
                                label:
                                    `${company.remoteId} / ${company.name}` ||
                                    "",
                                value: { ...company },
                            }))
                        );
                        const selectedDropdownItem = payload.find(
                            (company) =>
                                company.remoteId === defaultKeyVaultCompanyId
                        );
                        if (selectedDropdownItem) {
                            setDefaultKeyVaultCompanyDropdownItem(
                                selectedDropdownItem
                            );
                        } else {
                            setIsCompaniesDropdownDisabled(false);
                        }
                    } else {
                        setIsCompaniesDropdownDisabled(false);
                    }
                }
            );
        }
    }, [
        loadCompanies,
        envMoniker,
        tenantMoniker,
        companiesData,
        defaultKeyVaultCompanyId,
    ]);

    useRefreshPage(() => {
        if (envMoniker && tenantId) {
            setIsTenantFetched(!isFetched);
        }
        setDefaultKeyVaultCompanyId(null);
        setCompaniesData([]);
        setDefaultKeyVaultCompanyDropdownItem(
            emptyDefaultKeyVaultCompanyDropdownItem
        );
        setIsCompaniesDropdownDisabled(true);
        setIsThrottlingPriorityDropdownDisabled(true);
        setConnectionInfoChanged(false);
        if (customerType === CustomerType.D365) {
            fetchTenantThrottlingPriorities(envMoniker, tenantId);
        }
    });

    useInactiveTenant();
    useInactiveEnvironment();

    const checkWorkflowProgressAndUpdateWorkflow = (
        settingWorkflow: ITenantFeatureSettingWorkFlow
    ) => {
        setFeatureSettingWorkFlows((featureSettingWorkFlows) => {
            return updateTenantSettingWorkFlow(
                featureSettingWorkFlows,
                settingWorkflow
            );
        });
    };

    const updateTenantErpConnectionInfo = () => {
        if (isConnectionInfoChanged && envMoniker && tenantId) {
            return updateErpConnectionInfo(
                envMoniker,
                tenantId,
                erpConnectionInfo
            ).then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setConnectionInfoFetched(false);
                    setConnectionInfoChanged(false);
                    if (payload.isValid) {
                        Alert.success(
                            payload.message,
                            constants.alertDurationMsec
                        );
                    } else {
                        Alert.error(
                            payload.message,
                            constants.errorAlertDurationMsec
                        );
                    }
                    return fetchErpConnectionInfo(envMoniker, tenantId);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    Alert.error(errorMessage, constants.alertDurationMsec);
                }
            });
        }
    };

    const updateTenantDefaultKeyVaultCompany = async () => {
        if (!isDefaultKeyVaultCompanyIdFetchedSuccessfully) {
            Alert.error(
                t(
                    "tenantConfig.d365InformationPage.defaultKeyVaultCompany.defaultKeyVaultCompanyUpdateFailed"
                ),
                constants.alertDurationMsec
            );
        } else if (defaultKeyVaultCompanyId) {
            try {
                const [payload, statusCode] =
                    await updateDefaultKeyVaultCompany(
                        envMoniker,
                        tenantId,
                        defaultKeyVaultCompanyId
                    );
                if (statusCode != constants.statusCode.NoContent) {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    Alert.error(
                        errorMessage ?? "",
                        constants.alertDurationMsec
                    );
                }
            } catch (error) {
                const errorMessage = `${t(
                    "tenantConfig.d365InformationPage.defaultKeyVaultCompany.defaultKeyVaultCompanyUpdateFailed"
                )}.\n${error}`;
                Alert.error(errorMessage, constants.alertDurationMsec);
            }
        }
    };

    const updateThrottlingPriority = async () => {
        if (!isThrottlingPrioritiesFetchedSuccessfully) {
            Alert.error(
                t(
                    "tenantConfig.d365InformationPage.throttlingPriority.throttlingPriorityUpdateFailed"
                ),
                constants.alertDurationMsec
            );
        } else if (throttlingPriority) {
            try {
                const [payload, statusCode] =
                    await updateTenantLinkedThrottlingPriority(
                        envMoniker,
                        tenantId,
                        throttlingPriority
                    );
                if (statusCode != constants.statusCode.NoContent) {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    Alert.error(errorMessage, constants.alertDurationMsec);
                }
            } catch (error) {
                const errorMessage = `${t(
                    "tenantConfig.d365InformationPage.throttlingPriority.throttlingPriorityUpdateFailed"
                )}.\n${error}`;
                Alert.error(errorMessage, constants.alertDurationMsec);
            }
        }
    };

    const handleErpThrottlingPriorityChange = (
        value: IErpThrottlingPriority
    ) => {
        setThrottlingPriorityDropdownItem(value);
        setThrottlingPriority(value);
        setIsUnsavedChangeAvailable(true);
    };

    const handleDismissWarning = () => {
        setIsDismissWarningInProgress(true);
        dismissDomainCheck(envMoniker, tenantMoniker).then(() => {
            setIsIntegrationUserValid(true);
            setIsDismissWarningInProgress(false);
        });
    };

    const renderDropdownItem = (_: ReactNode, item: ItemDataType) => {
        return (
            <div key={item.value.remoteId}>
                <div>{item.value.remoteId}</div>
                <div>{item.value.name}</div>
            </div>
        );
    };

    const handleSearchBy = (
        keyword: string,
        label: ReactNode,
        item: ItemDataType
    ) => {
        return (
            item.value?.remoteId
                ?.toLowerCase()
                .includes(keyword.toLowerCase()) ||
            label?.toString()?.toLowerCase().includes(keyword.toLowerCase())
        );
    };

    const handleDefaultKeyVaultCompanyChange = (value: ITenantCompany) => {
        setDefaultKeyVaultCompanyId(value?.remoteId || "");
        setDefaultKeyVaultCompanyDropdownItem(value);
        setIsUnsavedChangeAvailable(true);
    };

    const handleFormSubmit = async () => {
        if (!formRef.current?.check()) {
            Alert.error(
                t("tenantConfig.generalConfigurationPage.warning"),
                constants.alertDurationMsec
            );
            return;
        }
        showLoader(true);
        await updateAllTenantConfigurations();
        setIsUnsavedChangeAvailable(false);
    };

    const updateAllTenantConfigurations = async () => {
        const promises: any[] = [];
        promises.push(updateTenantErpConnectionInfo());

        Promise.all(promises)
            .then(() =>
                fetchIsIntegrationUserDomainValid(envMoniker, tenantMoniker)
            )
            .then(() => updateTenantDefaultKeyVaultCompany())
            .then(() => updateThrottlingPriority())
            .then(() => showLoader(false));
    };

    return (
        (isFetched && (
            <SignalRTenantFeatureSetting
                tenantId={tenantId}
                updateTenantFeatureSettingWorkFlows={(
                    settingWorkflow: ITenantFeatureSettingWorkFlow
                ) => {
                    checkWorkflowProgressAndUpdateWorkflow(settingWorkflow);
                }}
            >
                <div className={"general-configurations__container"}>
                    <div className="general-configurations__content">
                        <h1 className={"general-configurations__page-header"}>
                            {template(
                                t("tenantConfig.d365InformationPage.header")
                            )({
                                customerType:
                                    customerType === CustomerType.AX2012
                                        ? t(
                                              "tenantConfig.d365InformationPage.customerTypeName.ax"
                                          )
                                        : t(
                                              "tenantConfig.d365InformationPage.customerTypeName.d365"
                                          ),
                            })}
                        </h1>
                        {!isIntegrationUserValid && isPasswordAuthentication && (
                            <Message
                                type="warning"
                                className={
                                    "general-configurations__warning-message"
                                }
                                showIcon
                                description={
                                    <>
                                        <p>
                                            {t(
                                                "tenantConfig.d365InformationPage.integrationUserWrongDomainMessage"
                                            )}
                                        </p>
                                        <div className="general-configuration__warning-message-button">
                                            <Button
                                                loading={
                                                    isDismissWarningInProgress
                                                }
                                                appearance="ghost"
                                                onClick={handleDismissWarning}
                                            >
                                                {t(
                                                    "tenantConfig.d365InformationPage.warningMessageDismissButton"
                                                )}
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        )}
                        <div>
                            <Form
                                model={formModel}
                                ref={formRef}
                                formValue={formValue}
                                className={
                                    "general-configurations__form--margin"
                                }
                            >
                                <div className={"general-configurations__form"}>
                                    <FormGroup
                                        className={
                                            "general-configurations__grid-item"
                                        }
                                    >
                                        <ControlLabel
                                            className={
                                                "general-configurations__label"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.d365InformationPage.defaultKeyVaultCompany.title"
                                            )}
                                            <HelpBlock
                                                className={
                                                    "general-configurations__help"
                                                }
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.d365InformationPage.defaultKeyVaultCompany.description"
                                                )}
                                            </HelpBlock>
                                        </ControlLabel>
                                        <FormControl
                                            disabled={
                                                isCompaniesDropdownDisabled
                                            }
                                            accepter={InputPicker}
                                            data={companiesData}
                                            renderMenuItem={renderDropdownItem}
                                            virtualized={false}
                                            searchBy={handleSearchBy}
                                            name={"defaultKeyVaultCompany"}
                                            placeholder={t(
                                                "tenantConfig.d365InformationPage.defaultKeyVaultCompany.placeholder"
                                            )}
                                            className={cn(
                                                "general-configurations__form-control",
                                                "general-configurations__company-input-picker"
                                            )}
                                            onChange={
                                                handleDefaultKeyVaultCompanyChange
                                            }
                                        />
                                    </FormGroup>
                                    {customerType === CustomerType.D365 && (
                                        <FormGroup
                                            className={
                                                "general-configurations__grid-item"
                                            }
                                        >
                                            <ControlLabel
                                                className={
                                                    "general-configurations__label"
                                                }
                                            >
                                                {t(
                                                    "tenantConfig.d365InformationPage.throttlingPriority.title"
                                                )}
                                                <HelpBlock
                                                    className={
                                                        "general-configurations__help"
                                                    }
                                                    tooltip
                                                >
                                                    {t(
                                                        "tenantConfig.d365InformationPage.throttlingPriority.description"
                                                    )}
                                                </HelpBlock>
                                            </ControlLabel>
                                            <FormControl
                                                disabled={
                                                    isThrottlingPriorityDropdownDisabled
                                                }
                                                accepter={InputPicker}
                                                cleanable={false}
                                                searchable={false}
                                                data={throttlingPrioritiesData}
                                                virtualized={false}
                                                name={"throttlingPriority"}
                                                placeholder={t(
                                                    "tenantConfig.d365InformationPage.throttlingPriority.placeholder"
                                                )}
                                                className={cn(
                                                    "general-configurations__form-control",
                                                    "general-configurations__company-input-picker"
                                                )}
                                                onChange={
                                                    handleErpThrottlingPriorityChange
                                                }
                                            />
                                        </FormGroup>
                                    )}

                                    <ErpConnectionInfoContainer
                                        authMethods={authMethods}
                                        erpConnectionInfo={erpConnectionInfo}
                                        defaultAuthenticationMethod={
                                            defaultAuthenticationMethod
                                        }
                                        authenticationMethod={
                                            authenticationMethod
                                        }
                                        isConnectionInfoFetched={
                                            isConnectionInfoFetched
                                        }
                                        customerType={customerType}
                                        formRef={formRef}
                                        setConnectionInfoChanged={
                                            setConnectionInfoChanged
                                        }
                                        setErpConnectionInfo={
                                            setErpConnectionInfo
                                        }
                                        setAuthenticationMethod={
                                            setAuthenticationMethod
                                        }
                                        isPasswordAuthentication={
                                            isPasswordAuthentication
                                        }
                                        setIsPasswordAuthentication={
                                            setIsPasswordAuthentication
                                        }
                                    />

                                    <ClientSecretFormGroup
                                        erpInfo={erpSecretsInfo}
                                        environmentMoniker={envMoniker}
                                        tenantMoniker={tenantMoniker}
                                        keyVaultAppRegistrationSecretRotationWorkflow={
                                            keyVaultAppRegistrationSecretRotationWorkflow
                                        }
                                    />
                                    <ResetD365Parameters
                                        envMoniker={envMoniker}
                                        tenantId={tenantId}
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                    <SettingsFooterContainer
                        handleFormSubmit={handleFormSubmit}
                    />
                </div>
            </SignalRTenantFeatureSetting>
        )) ||
        null
    );
};

export default ErpConfigurations;
