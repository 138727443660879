import React from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "components/Common/Modal/ModalContainer";
import { Button, Table } from "rsuite";
import { IMailboxErrorLog } from "redux/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";
import "./MailboxesErrorLogModal.less";

interface ComponentProps {
    errorLogs: IMailboxErrorLog[];
    isOpened: boolean;
    setIsOpened: (isOpened: boolean) => void;
}

const MailboxesErrorLogModal: React.FC<ComponentProps> = ({
    errorLogs,
    isOpened,
    setIsOpened,
}: ComponentProps) => {
    const { t } = useTranslation();

    const handleModalClose = () => {
        setIsOpened(false);
    };

    const header = <h1>{t("tenantConfig.captureMailboxes.errorLogsTitle")}</h1>;
    const footer = (
        <>
            <Button
                className={"mailboxes-error-log-modal__button"}
                type="submit"
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("deliverables.packages.history.close")}
            </Button>
        </>
    );

    return (
        <ModalContainer
            show={isOpened}
            className={"mailboxes-error-log-modal__modal"}
            header={header}
            onHide={handleModalClose}
            footer={footer}
        >
            <Table
                shouldUpdateScroll={false}
                virtualized={true}
                data={errorLogs}
            >
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>
                        {t(
                            `tenantConfig.captureMailboxes.table.mailboxAddress`
                        )}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="mailboxAddress">
                        {(rowData: IMailboxErrorLog) => {
                            return (
                                <PopoverCell
                                    dataKey="mailboxAddress"
                                    rowData={rowData}
                                />
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>
                        {t(`tenantConfig.captureMailboxes.table.failureReason`)}
                    </Table.HeaderCell>
                    <Table.Cell dataKey="failureReason">
                        {(rowData: IMailboxErrorLog) => {
                            return (
                                <PopoverCell
                                    dataKey="failureReason"
                                    rowData={rowData}
                                />
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </ModalContainer>
    );
};

export default MailboxesErrorLogModal;
